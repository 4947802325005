import React from 'react';
import { Hourglass } from 'react-loader-spinner';

const LoaderSpinner = ({ isLoading }) => {
    if (!isLoading) {
        return null;
    }

    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 z-50">
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-300 bg-opacity-70"></div>
            <div className="flex justify-center items-center h-full">
                <Hourglass
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="hourglass-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    colors={['#306cce', '#72a1ed']}
                />
            </div>
        </div>
    );
};

export default LoaderSpinner;
