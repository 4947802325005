/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { supportTicketColumns } from "./variables/columnData";
import SupportTicketTable from "./components/supportTicketTable";

import { getSupportTicketUniversity } from "../../../apis";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

const SupportTickets = () => {
  const [imageOpen, setImageOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [vettingVideoRecord, setVettingVideoRecrod] = useState([]);
  const [updateRecords, setUpdateRecords] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  //   const [vettingRecords, setVettingRecords] = useState([]);
  //   const [exams, setExams] = useState([]);
  //   const [selectedOption, setSelectedOption] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  //   const universityId = useSelector((state) => state.identifier.id);
  const handleImageOpen = (imageSrc) => {
    console.log("Image src ", imageSrc);
    setImgSrc(imageSrc);
    setImageOpen(true);
  };

  const handleImageClose = () => {
    setImageOpen(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getSupportTicketUniversity();
        // setVettingRecords(response.data.records);
        setFilteredData(response.data.tickets);
      } catch (error) {
        console.log("error ");
      }
    })();
  }, [updateRecords]);

  return (
    <div>
      <SupportTicketTable
        columnsData={supportTicketColumns}
        tableData={filteredData}
        tableTittle={"Support Tickets"}
        handleImageOpen={handleImageOpen}
        handleImageClose={handleImageClose}
      />
      {imageOpen && (
        <Lightbox
          mainSrc={imgSrc[photoIndex]}
          nextSrc={imgSrc[(photoIndex + 1) % imgSrc.length]}
          prevSrc={imgSrc[(photoIndex + imgSrc.length - 1) % imgSrc.length]}
          onCloseRequest={handleImageClose}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + imgSrc.length - 1) % imgSrc.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imgSrc.length)
          }
        />
      )}
    </div>
  );
};

export default SupportTickets;
