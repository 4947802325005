import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastService = {
  success: (message) => {
    toast.success(message);
  },

  info: (message) => {
    toast.info(message);
  },

  warning: (message) => {
    toast.warning(message);
  },

  error: (message) => {
    toast.error(message);
  },

  default: (message) => {
    toast(message);
  },

  congratulations: (message) => {
    toast.success(message, { icon: "🎉" });
  },
};

export default toastService;
