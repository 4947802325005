import { updateExistingSkillEngineray } from "apis";
import React, { useState } from "react";
import toastService from "services/toastService";
import circle_logo_placeholder from "../../../../assets/img/placeholders/circle_logo_placeholder.png";
const SkillEditModal = ({ isOpen, onClose, skill, refreshSkills }) => {
  const [newImage, setNewImage] = useState(null);
  const [image, setImage] = useState(
    skill.image
      ? `${process.env.REACT_APP_BACKEND_URL}/${skill.image}`
      : circle_logo_placeholder
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file type
      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        setErrorMessage(
          "Invalid file format. Please upload JPG, JPEG, PNG, or GIF."
        );
        return;
      }

      // Check file size (5MB max)
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage(
          "File size exceeds 5MB. Please upload a smaller image."
        );
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      //onUpload(file);
      setNewImage(file);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a valid image.");
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    let data = {
      skillId: skill.id,
      name: skill.name,
      tags: skill.tags ? skill.tags.tags.join(",") : "",
    };

    if (newImage) {
      data = { ...data, skillLogo: newImage };
    }

    try {
      const response = await updateExistingSkillEngineray(data);
      if (response.status === 200) {
        toastService.success("Skill updated");
      } else {
        toastService.info(response.data.message);
      }
    } catch (error) {
      toastService.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
      refreshSkills();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-60 backdrop-blur">
      <div className="relative flex w-2/5 flex-col rounded-xl bg-white p-6">
        <button
          className="absolute top-3 right-3 text-lg font-bold"
          onClick={onClose}
        >
          X
        </button>
        <h2 className="mt-5 text-2xl font-bold">Edit Skill</h2>

        <div className="flex flex-col items-center">
          <div className="relative mb-4 h-20 w-20">
            {image ? (
              <img
                src={image}
                alt="Uploaded Preview"
                className="  absolute top-0   left-0 h-full w-full rounded-full border-2 border-blue-500 object-cover"
              />
            ) : (
              <div className="flex h-16 w-16 items-center justify-center rounded-full bg-gray-200">
                <span className="text-gray-400">No Image</span>
              </div>
            )}
          </div>
          <label className="my-5 inline-block cursor-pointer text-blue-500">
            {image ? "Select a Different Logo" : "Upload Logo"}

            <input
              type="file"
              className="hidden"
              onChange={handleImageChange}
              accept=".jpg, .jpeg, .png"
            />
          </label>
          {errorMessage && <p className="mt-2 text-red-500">{errorMessage}</p>}
        </div>
        <div className="my-3">
          <label
            htmlFor="skillName"
            className="block text-lg font-semibold text-gray-800 "
          >
            Skill
          </label>
          <input
            id="skillName"
            name="skillName"
            type="text"
            value={skill.name}
            readOnly={true}
            placeholder="First name"
            className="bg-slate-100 mt-1 w-1/2 cursor-auto rounded-md border border-gray-500 bg-gray-200 p-2 focus:outline-none"
          />
        </div>
        <div className="my-3">
          <label
            htmlFor="skillTags"
            className="block text-lg font-semibold text-gray-800"
          >
            Tags
          </label>
          <input
            id="skillTags"
            name="skillTags"
            type="text"
            value={skill.tags ? skill.tags.tags.join(", ") : ""}
            readOnly={true}
            placeholder="First name"
            className="bg-slate-100 mt-1 w-1/2 cursor-auto rounded-md border border-gray-500 bg-gray-200 p-2 focus:outline-none"
          />
        </div>
        <div className="flex w-full justify-center">
          <button
            onClick={() => {
              handleUpdate();
            }}
            disabled={!newImage || loading}
            className="my-5 w-1/3 rounded bg-blue-500 px-4 py-2 text-white disabled:bg-blue-300"
          >
            {loading ? "Updating" : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SkillEditModal;
