export const examFeedbackColumns = [
  {
    Header: "APPLICANT",
    accessor: "applicant",
  },
  {
    Header: "EXAM TYPE",
    accessor: "examType",
  },
  {
    Header: "Skill",
    accessor: "skill",
  },
  {
    Header: "OVERALL COMMENTS",
    accessor: "overallComments",
  },
  {
    Header: "OVERALL RATING",
    accessor: "overallRating",
  },
  {
    Header: "DATE",
    accessor: "createdAt",
  },
  {
    Header: "ACTIONS",
    accessor: "action",
  },
];
