export const codingProblemTableColumns = [
  {
    Header: "#",
    accessor: "idx",
  },
  {
    Header: "TITLE",
    accessor: "title",
  },
  {
    Header: "DIFFICULTY",
    accessor: "difficulty",
  },
  {
    Header: "CREATED BY",
    accessor: "company",
  },
  {
    Header: "TAGs",
    accessor: "tags",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
