/* eslint-disable no-unused-vars */
import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import RTLDefault from "views/rtl/default";
import VideoVetting from "views/admin/videoVetting/";
import UniversityVetting from "views/admin/universityVetting";
import VettingRecordsError from "views/admin/failedVettingUniversity";
import Skills from "views/admin/skills";
import Questions from "views/admin/questions";
import CodingProblems from "views/admin/codingProblems";
import ExamFeedback from "views/admin/examFeedback";
import DemoRequests from "views/admin/demoRequests";
// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdSchool,
  MdVideocam,
  MdLocalPlay,
  MdListAlt,
  MdSupportAgent,
  MdFeedback,
  MdSettings,
  MdCode,
  MdOutlineAppSettingsAlt,
  MdPersonalVideo,
} from "react-icons/md";
import SupportTickets from "views/admin/supportTickets";
import EnginerayConfiguration from "views/admin/enginerayConfiguration";
import PlanConfiguration from "views/admin/planConfiguration";
import FeatureFlags from "views/admin/featureFlags";

const routes = [
  // {
  //   name: "Main Dashboard",
  //   layout: "/admin",
  //   path: "default",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <MainDashboard />,
  // },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignIn />,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
  {
    name: "Review Results",
    layout: "/admin",
    path: "video-vetting",
    icon: <MdVideocam className="h-6 w-6" />,
    component: <VideoVetting />,
  },
  {
    name: "Engineray Configuration",
    layout: "/admin",
    path: "engineray-configuration",
    icon: <MdSettings className="h-6 w-6" />,
    component: <EnginerayConfiguration />,
  },
  {
    name: "Features Availability",
    layout: "/admin",
    path: "feature-flags",
    icon: <MdOutlineAppSettingsAlt className="h-6 w-6" />,
    component: <FeatureFlags />,
  },
  {
    name: "Plan & Features",
    layout: "/admin",
    path: "plan-configuration",
    icon: <MdOutlineAppSettingsAlt className="h-6 w-6" />,
    component: <PlanConfiguration />,
  },
  {
    name: "Add Skills",
    layout: "/admin",
    path: "skills",
    icon: <MdLocalPlay className="h-6 w-6" />,
    component: <Skills />,
  },
  {
    name: "Add Domain Questions",
    layout: "/admin",
    path: "questions",
    icon: <MdListAlt className="h-6 w-6" />,
    component: <Questions />,
  },
  {
    name: "Add Coding Problems",
    layout: "/admin",
    path: "coding-problems",
    icon: <MdCode className="h-6 w-6" />,
    component: <CodingProblems />,
  },
  // {
  //   name: "Review University Results",
  //   layout: "/admin",
  //   path: "university-vetting",
  //   icon: <MdSchool className="h-6 w-6" />,
  //   component: <UniversityVetting />,
  // },
  {
    name: "Support Tickets",
    layout: "/admin",
    path: "support-tickets",
    icon: <MdSupportAgent className="h-6 w-6" />,
    component: <SupportTickets />,
  },
  {
    name: "Exam Feedback",
    layout: "/admin",
    path: "exam-feedback",
    icon: <MdFeedback className="h-6 w-6" />,
    component: <ExamFeedback />,
  },
  {
    name: "Demo Requests",
    layout: "/admin",
    path: "demo-requests",
    icon: <MdPersonalVideo className="h-6 w-6" />,
    component: <DemoRequests />,
  },
];
export default routes;
