import { useRef, useState, useEffect } from "react";

import { useFormik } from "formik";

import {
  addNewSkillWithQuestions,
  getAllSkillsAndQuestionCount,
  addNewSkillTopicGenerator,
  addNewSkillWithQuestionsOpenEnded,
} from "apis";
import toastService from "services/toastService";
import { ThreeDots } from "react-loader-spinner";
import SkillsTable from "./components/SkillsTable";
import { skillTableColumns } from "./variables/columnsData";
import SkillEditModal from "./components/SkillEditModal";
const Skills = () => {
  const fileInputRef = useRef(null);
  const imageFileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingGenerateQuestions, setLoadingGenerateQuestions] =
    useState(false);
  const [skillRecords, setSkillRecords] = useState([]);
  const [updateRecords, setUpdateRecords] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editRecord, setEditRecord] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllSkillsAndQuestionCount();
        setSkillRecords(response.data.skills);
      } catch (error) {
        console.log("error ");
      }
    })();
  }, [updateRecords]);

  const handleOpenModal = () => {
    setViewModal(true);
  };
  const handleCloseModal = () => {
    setViewModal(false);
  };

  const clearFile = () => {
    formik.setFieldValue("csvFile", null);

    // Use the ref to clear the file input's display value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const clearFileImage = () => {
    formik.setFieldValue("skillLogo", null);

    // Use the ref to clear the file input's display value
    if (imageFileInputRef.current) {
      imageFileInputRef.current.value = "";
    }
  };

  function toggleUpdateRecords() {
    const newState = !updateRecords;
    setUpdateRecords(newState);
  }

  const onEditSkillClickHandler = async (skillId) => {
    console.log("Skill id:", skillId);
    const skillToEdit = skillRecords.find((skill) => skill.id === skillId);
    if (skillToEdit) {
      console.log("Skill to edit:", skillToEdit);
      setEditRecord(skillToEdit);
      // Further logic to edit the skill
      handleOpenModal();
    } else {
      console.log("Skill not found");
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.skillName) {
      errors.skillName = "Required";
    }
    if (!values.questionType) {
      errors.questionType = "Required";
    }

    return errors;
  };

  const validate_1 = (values) => {
    const errors = {};
    if (!values.topicName) {
      errors.topicName = "Required";
    }
    if (!values.numberOfQuestions && values.numberOfQuestions !== 0) {
      errors.numberOfQuestions = "Required";
    }
    if (values.numberOfQuestions < 1) {
      errors.numberOfQuestions = "Minimum number of questions is 1 ";
    }
    if (!values.questionType) {
      errors.questionType = "Required";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      skillName: "",
      csvFile: null,
      skillLogo: null,
      questionType: "",
    },
    validate,
    onSubmit: async (values) => {
      console.log("Form values:", values);

      // Here you can handle the file data further, e.g., uploading to a server
      const file = values.csvFile;
      const skillLogo = values.skillLogo;
      const questionType = values.questionType;

      console.log("Question Type :", questionType);

      let payload = {
        title: values.skillName,
      };

      if (file) {
        console.log(`Uploaded file name: ${file.name}`);
        payload = {
          ...payload,
          csvData: file,
        };
      }

      if (questionType === "mcq") {
        if (skillLogo) {
          console.log(`Skill logo name: ${skillLogo.name}`);
          payload = {
            ...payload,
            skillLogo: skillLogo,
          };
        }

        setLoading(true);
        console.log("Pay load ", payload);

        const response = await addNewSkillWithQuestions(payload);

        if (response.status === 201) {
          toastService.success("Skill Added");
          toggleUpdateRecords();
        } else if (response.status === 409) {
          toastService.warning("Skill Already Exists");
        } else {
          toastService.error(response.data.message);
        }
        setLoading(false);
        formik.resetForm();
        clearFile();
        clearFileImage();
      } else {
        setLoading(true);
        console.log("Pay load ", payload);

        const response = await addNewSkillWithQuestionsOpenEnded(payload);

        if (response.status === 201) {
          toastService.success("Skill Added");
          toggleUpdateRecords();
        } else if (response.status === 409) {
          toastService.warning("Skill Already Exists");
        } else {
          toastService.error(response.data.message);
        }
        setLoading(false);
        formik.resetForm();
        clearFile();
        clearFileImage();
      }
    },
  });

  const formikGenerateQuestions = useFormik({
    initialValues: {
      topicName: "",
      numberOfQuestions: 100,
      questionType: "", // initial value for radio buttons
    },
    validate: validate_1,
    onSubmit: async (values) => {
      console.log("Form values:", values);

      // Here you can handle the file data further, e.g., uploading to a server

      let payload;

      payload = {
        topicName: values.topicName,
        numberOfQuestions: values.numberOfQuestions,
        questionType: values.questionType,
      };

      setLoadingGenerateQuestions(true);
      console.log("Pay load ", payload);

      const response = await addNewSkillTopicGenerator(payload);

      if (response.status === 200) {
        toastService.success("Request Submitted.");
        //toggleUpdateRecords();
      } else if (response.status === 409) {
        toastService.warning("Skill Already Exists");
      } else {
        toastService.error("Something went wrong");
      }
      setLoadingGenerateQuestions(false);
      formikGenerateQuestions.resetForm();
    },
  });
  return (
    <div>
      <div
        className={
          "z-10 flex h-full w-full flex-col  rounded-[20px] bg-white bg-clip-border p-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto"
        }
      >
        <div className="relative flex items-center ">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {"Generate Questions for Skill"}
          </div>
        </div>
        <div className="min-w-screen flex items-center justify-center rounded-xl bg-gray-100 p-1">
          <form
            onSubmit={formikGenerateQuestions.handleSubmit}
            className="flex w-full items-center space-x-6 rounded-xl bg-white p-8 shadow-lg"
          >
            {/* Text Input */}
            <div>
              <label
                htmlFor="topicName"
                className="block text-sm font-medium text-gray-700"
              >
                Topic Name
              </label>
              <input
                id="topicName"
                name="topicName"
                type="text"
                placeholder="Enter topic name ..."
                className={`mt-1 border p-2 ${
                  formikGenerateQuestions.errors.topicName &&
                  formikGenerateQuestions.touched.topicName
                    ? "border-red-500"
                    : ""
                } rounded-md focus:outline-none focus:ring focus:ring-blue-200`}
                onChange={formikGenerateQuestions.handleChange}
                onBlur={formikGenerateQuestions.handleBlur}
                value={formikGenerateQuestions.values.topicName}
              />
              {formikGenerateQuestions.errors.topicName &&
              formikGenerateQuestions.touched.topicName ? (
                <div className="mt-1 text-sm text-red-500">
                  {formikGenerateQuestions.errors.topicName}
                </div>
              ) : null}
            </div>

            {/* File Input */}
            <div>
              <label
                htmlFor="numberOfQuestions"
                className="block text-sm font-medium text-gray-700"
              >
                Number of Questions
              </label>
              <input
                id="numberOfQuestions"
                name="numberOfQuestions"
                type="number"
                min={1}
                className={`mt-1 border p-2 ${
                  formikGenerateQuestions.errors.numberOfQuestions &&
                  formikGenerateQuestions.touched.numberOfQuestions
                    ? "border-red-500"
                    : ""
                } rounded-md focus:outline-none focus:ring focus:ring-blue-200`}
                onChange={formikGenerateQuestions.handleChange}
                onBlur={formikGenerateQuestions.handleBlur}
                value={formikGenerateQuestions.values.numberOfQuestions}
              />
              {formikGenerateQuestions.errors.numberOfQuestions &&
              formikGenerateQuestions.touched.numberOfQuestions ? (
                <div className="mt-1 text-sm text-red-500">
                  {formikGenerateQuestions.errors.numberOfQuestions}
                </div>
              ) : null}
            </div>
            {/* Radio Buttons */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Question Type
              </label>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="questionType"
                  value="mcq"
                  checked={
                    formikGenerateQuestions.values.questionType === "mcq"
                  }
                  onChange={formikGenerateQuestions.handleChange}
                  className="mr-2"
                />
                <label className="mr-4">MCQ</label>

                <input
                  type="radio"
                  name="questionType"
                  value="open_ended"
                  checked={
                    formikGenerateQuestions.values.questionType === "open_ended"
                  }
                  onChange={formikGenerateQuestions.handleChange}
                  className="mr-2"
                />
                <label>Open Ended</label>
              </div>
              {formikGenerateQuestions.errors.questionType && (
                <div className="mt-1 text-sm text-red-500">
                  {formikGenerateQuestions.errors.questionType}
                </div>
              )}
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className={`mt-5 rounded-md bg-blue-500 py-2 px-4 text-white transition duration-200 hover:bg-blue-600 ${
                  formikGenerateQuestions.errors.topicName ||
                  !formikGenerateQuestions.values.topicName ||
                  formikGenerateQuestions.errors.numberOfQuestions ||
                  !formikGenerateQuestions.values.numberOfQuestions ||
                  formikGenerateQuestions.errors.questionType ||
                  !formikGenerateQuestions.values.questionType ||
                  loadingGenerateQuestions
                    ? " cursor-not-allowed opacity-60"
                    : " "
                }`}
                disabled={
                  formikGenerateQuestions.errors.topicName ||
                  !formikGenerateQuestions.values.topicName ||
                  formikGenerateQuestions.errors.numberOfQuestions ||
                  !formikGenerateQuestions.values.numberOfQuestions ||
                  formikGenerateQuestions.errors.questionType ||
                  !formikGenerateQuestions.values.questionType ||
                  loadingGenerateQuestions
                }
              >
                <div className="flex flex-row items-center justify-center gap-5">
                  <span>
                    {loadingGenerateQuestions ? "Submitting..." : " Submit"}
                  </span>
                  <span>
                    <ThreeDots
                      height="30"
                      width="30"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={loadingGenerateQuestions}
                    />
                  </span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={
          "z-10 flex h-full w-full flex-col  rounded-[20px] bg-white bg-clip-border p-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto"
        }
        extra={" p-4 sm:overflow-x-auto"}
      >
        <div className="relative flex items-center ">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {"Add a new Skill"}
          </div>
        </div>
        <div className="min-w-screen flex items-center justify-center rounded-xl bg-gray-100 p-1">
          <form
            onSubmit={formik.handleSubmit}
            className="flex w-full items-center space-x-6 rounded-xl bg-white p-8 shadow-lg"
          >
            {/* Text Input */}
            <div>
              <label
                htmlFor="skillName"
                className="block text-sm font-medium text-gray-700"
              >
                Skill Name
              </label>
              <input
                id="skillName"
                name="skillName"
                type="text"
                placeholder="Enter skill name ..."
                className={`mt-1 border p-2 ${
                  formik.errors.skillName && formik.touched.skillName
                    ? "border-red-500"
                    : ""
                } rounded-md focus:outline-none focus:ring focus:ring-blue-200`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.skillName}
              />
              {formik.errors.skillName && formik.touched.skillName ? (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.skillName}
                </div>
              ) : null}
            </div>
            {/* Radio Buttons */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Question Type
              </label>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="questionType"
                  value="mcq"
                  checked={formik.values.questionType === "mcq"}
                  onChange={formik.handleChange}
                  className="mr-2"
                />
                <label className="mr-4">MCQ</label>

                <input
                  type="radio"
                  name="questionType"
                  value="open_ended"
                  checked={formik.values.questionType === "open_ended"}
                  onChange={formik.handleChange}
                  className="mr-2"
                />
                <label>Open Ended</label>
              </div>
              {formik.errors.questionType && (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.questionType}
                </div>
              )}
            </div>

            {/* File Input */}
            <div>
              <label
                htmlFor="fileField"
                className="block text-sm font-medium text-gray-700"
              >
                Questions CSV File
              </label>
              <input
                ref={fileInputRef}
                id="csvFile"
                name="csvFile"
                type="file"
                accept=".csv"
                className="mt-1 rounded-md border p-2 focus:outline-none focus:ring focus:ring-blue-200"
                onChange={(event) => {
                  formik.setFieldValue("csvFile", event.currentTarget.files[0]);
                }}
              />
              {formik.values.csvFile && (
                <button
                  type="button"
                  className="ml-4 rounded-md bg-red-500 py-1 px-2 text-white transition duration-200 hover:bg-red-600"
                  onClick={clearFile}
                >
                  Clear File
                </button>
              )}
            </div>
            <div>
              <label
                htmlFor="skillLogo"
                className="block text-sm font-medium text-gray-700"
              >
                Skill Logo
              </label>
              <input
                ref={imageFileInputRef}
                id="skillLogo"
                name="skillLogo"
                type="file"
                accept=".jpg, .jpeg, .png"
                className="mt-1 rounded-md border p-2 focus:outline-none focus:ring focus:ring-blue-200"
                onChange={(event) => {
                  formik.setFieldValue(
                    "skillLogo",
                    event.currentTarget.files[0]
                  );
                }}
              />
              {formik.values.skillLogo && (
                <button
                  type="button"
                  className="ml-4 rounded-md bg-red-500 py-1 px-2 text-white transition duration-200 hover:bg-red-600"
                  onClick={clearFileImage}
                >
                  Clear File
                </button>
              )}
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className={`mt-5 rounded-md bg-blue-500 py-2 px-4 text-white transition duration-200 hover:bg-blue-600 ${
                  formik.errors.skillName ||
                  formik.errors.questionType ||
                  !formik.values.skillName ||
                  !formik.values.questionType ||
                  loading
                    ? " cursor-not-allowed opacity-60"
                    : " "
                }`}
                disabled={
                  formik.errors.skillName ||
                  formik.errors.questionType ||
                  !formik.values.skillName ||
                  !formik.values.questionType ||
                  loading
                }
              >
                <div className="flex flex-row items-center justify-center gap-5">
                  <span>{loading ? "Submitting..." : " Submit"}</span>
                  <span>
                    <ThreeDots
                      height="30"
                      width="30"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={loading}
                    />
                  </span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
      <SkillsTable
        columnsData={skillTableColumns}
        tableData={skillRecords}
        editClickHandler={onEditSkillClickHandler}
      />

      {viewModal && (
        <SkillEditModal
          skill={editRecord}
          isOpen={viewModal}
          onClose={handleCloseModal}
          refreshSkills={toggleUpdateRecords}
        />
      )}
    </div>
  );
};

export default Skills;
