/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { examFeedbackColumns } from "./variables/columnData";
import ExamFeedbackTable from "./components/examFeedbackTable";

import { getExamFeedbackApplicants, getExamFeedback } from "../../../apis";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import DisplayFeedbackModal from "./components/DisplayFeedbackModal";

const SupportTickets = () => {
  const [imageOpen, setImageOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [vettingVideoRecord, setVettingVideoRecrod] = useState([]);
  const [updateRecords, setUpdateRecords] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [feedbackRecord, setFeedbackRecord] = useState([]);
  //   const [exams, setExams] = useState([]);
  //   const [selectedOption, setSelectedOption] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  //   const universityId = useSelector((state) => state.identifier.id);
  const handleImageOpen = (imageSrc) => {
    console.log("Image src ", imageSrc);
    setImgSrc(imageSrc);
    setImageOpen(true);
  };

  const onVideoClickHandler = async (recordId) => {
    console.log("Record id :", recordId);

    const response = await getExamFeedback(recordId);
    if (response.status === 200) {
      console.log("Record : ", response.data.feedback);
      setFeedbackRecord(response.data.feedback);
      handleOpenModal();
    } else {
      console.log("Error ");
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleImageClose = () => {
    setImageOpen(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getExamFeedbackApplicants();
        // setVettingRecords(response.data.records);
        setFilteredData(response.data.feedback);
      } catch (error) {
        console.log("error ");
      }
    })();
  }, [updateRecords]);

  return (
    <div>
      <ExamFeedbackTable
        columnsData={examFeedbackColumns}
        tableData={filteredData}
        tableTittle={"Support Tickets"}
        handleViewFeedback={onVideoClickHandler}
      />
      {imageOpen && (
        <Lightbox
          mainSrc={imgSrc[photoIndex]}
          nextSrc={imgSrc[(photoIndex + 1) % imgSrc.length]}
          prevSrc={imgSrc[(photoIndex + imgSrc.length - 1) % imgSrc.length]}
          onCloseRequest={handleImageClose}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + imgSrc.length - 1) % imgSrc.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imgSrc.length)
          }
        />
      )}
      {showModal && (
        <DisplayFeedbackModal
          isOpen={showModal}
          onClose={handleCloseModal}
          feedback={feedbackRecord}
        />
      )}
    </div>
  );
};

export default SupportTickets;
