import React, { useState } from "react";

import { updateDemoRequestStatus } from "../../../../apis";
import toastService from "../../../../services/toastService";

function DemoRequestModal({ demoRequest, onClose, setRefresh }) {
  const [status, setStatus] = useState(demoRequest.status);
  const [isUpdating, setIsUpdating] = useState(false);

  const statuses = ["PENDING", "RESPONDED", "SCHEDULED"]; // Possible statuses

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleUpdate = async () => {
    setIsUpdating(true);
    try {
      const response = await updateDemoRequestStatus({
        demoRequestId: demoRequest.id,
        status: status,
      });
      if (response.status === 200) {
        toastService.success("Status updated successfully.");
        setRefresh((prevState) => !prevState);
        onClose(); // Close the modal on successful update
      } else {
        toastService.error("Failed to update status.");
      }
    } catch (error) {
      toastService.error("Something went wrong.");
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="w-full max-w-lg rounded-lg bg-white p-6 shadow-lg">
        <h2 className="mb-4 text-xl font-semibold">Demo Request Info</h2>

        {/* Request Information */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <div className="mt-1 rounded-md border border-gray-300 p-2">
              {demoRequest.name}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div className="mt-1 rounded-md border border-gray-300 p-2">
              {demoRequest.email}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Company Name
            </label>
            <div className="mt-1 rounded-md border border-gray-300 p-2">
              {demoRequest.companyName}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Contact Number
            </label>
            <div className="mt-1 rounded-md border border-gray-300 p-2">
              {demoRequest.contactNumber}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Country
            </label>
            <div className="mt-1 rounded-md border border-gray-300 p-2">
              {demoRequest.country}
            </div>
          </div>

          {/* Editable Status */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Status
            </label>
            <select
              value={status}
              onChange={handleStatusChange}
              className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
            >
              {statuses.map((statusOption) => (
                <option key={statusOption} value={statusOption}>
                  {statusOption}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Buttons */}
        <div className="mt-6 flex justify-end space-x-4">
          <button
            className="rounded-md bg-gray-300 py-2 px-4 text-gray-700 hover:bg-gray-400"
            onClick={onClose}
            disabled={isUpdating}
          >
            Cancel
          </button>
          <button
            className={`rounded-md bg-blue-600 py-2 px-4 text-white hover:bg-blue-700 ${
              isUpdating ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={handleUpdate}
            disabled={isUpdating}
          >
            {isUpdating ? "Updating..." : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DemoRequestModal;
