import React from "react";
import DisplayStarRating from "./DisplayStarRating";

const DisplayFeedbackModal = ({ isOpen, onClose, feedback }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* <div className="bg-black fixed inset-0 opacity-60 backdrop-blur-sm backdrop-filter"></div> */}
      <div className="modal-overlay absolute h-screen w-screen bg-gray-900 opacity-50"></div>
      <div className="relative z-50 h-5/6 w-11/12 overflow-y-auto rounded-lg bg-white p-8 shadow-lg md:w-3/4 lg:w-2/3 xl:w-3/4">
        <button
          className="absolute top-4 right-4 text-3xl text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          ×
        </button>
        <h2 className="mb-4 text-center text-2xl font-bold">Exam Feedback</h2>
        <div className="mb-4">
          <section className="rounded-lg border bg-white p-6 shadow">
            <h2 className=" mb-4 text-lg font-semibold text-gray-800 lg:text-2xl">
              Exam Details
            </h2>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label className="block font-bold text-gray-700">
                  Applicant Name
                </label>
                <p className="mt-1 rounded-md bg-gray-100 p-3">{`${feedback.applicant}`}</p>
              </div>
              <div>
                <label className="block font-bold text-gray-700">
                  Exam Type
                </label>
                <p className="mt-1 rounded-md bg-gray-100 p-3">
                  {feedback.examType}
                </p>
              </div>
              <div>
                <label className="block font-bold text-gray-700">Skill</label>
                <p className="mt-1 rounded-md bg-gray-100 p-3">
                  {feedback.skill}
                </p>
              </div>
            </div>
          </section>
        </div>
        <div className="mb-4">
          <section className="rounded-lg border bg-white p-6 shadow">
            <h2 className=" mb-4 text-lg font-semibold text-gray-800 lg:text-2xl">
              Overall Experience
            </h2>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label className="block font-bold text-gray-700">Rating</label>
                <DisplayStarRating
                  stars={feedback.overallExperience}
                  size="4xl"
                />
              </div>
              <div>
                <label className="block font-bold text-gray-700">
                  Comments
                </label>
                <p className="h-[15vh] overflow-auto bg-gray-100 p-4">
                  {feedback.overallExperienceComment}
                </p>
              </div>
            </div>
          </section>
        </div>
        <div className="mb-4">
          <section className="rounded-lg border bg-white p-6 shadow">
            <h2 className=" mb-4 text-lg font-semibold text-gray-800 lg:text-2xl">
              Ease of Following Guidelines
            </h2>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label className="block font-bold text-gray-700">Rating</label>
                <DisplayStarRating stars={feedback.guidelinesEase} size="4xl" />
              </div>
              <div>
                <label className="block font-bold text-gray-700">
                  Comments
                </label>
                <p className="h-[15vh] overflow-auto bg-gray-100 p-4">
                  {feedback.guidelinesEaseComment}
                </p>
              </div>
            </div>
          </section>
        </div>
        <div className="mb-4">
          <section className="rounded-lg border bg-white p-6 shadow">
            <h2 className=" mb-4 text-lg font-semibold text-gray-800 lg:text-2xl">
              Exam Checklist
            </h2>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label className="block font-bold text-gray-700">Rating</label>
                <DisplayStarRating stars={feedback.examChecklist} size="4xl" />
              </div>
              <div>
                <label className="block font-bold text-gray-700">
                  Comments
                </label>
                <p className="h-[15vh] overflow-auto bg-gray-100 p-4">
                  {feedback.examChecklistComment}
                </p>
              </div>
            </div>
          </section>
        </div>
        <div className="mb-4">
          <section className="rounded-lg border bg-white p-6 shadow">
            <h2 className=" mb-4 text-lg font-semibold text-gray-800 lg:text-2xl">
              Exam Interface
            </h2>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label className="block font-bold text-gray-700">Rating</label>
                <DisplayStarRating stars={feedback.examInterface} size="4xl" />
              </div>
              <div>
                <label className="block font-bold text-gray-700">
                  Comments
                </label>
                <p className="h-[15vh] overflow-auto bg-gray-100 p-4">
                  {feedback.examInterfaceComment}
                </p>
              </div>
            </div>
          </section>
        </div>
        <div className="mb-4">
          <section className="rounded-lg border bg-white p-6 shadow">
            <h2 className=" mb-4 text-lg font-semibold text-gray-800 lg:text-2xl">
              Question Difficulty
            </h2>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label className="block font-bold text-gray-700">Rating</label>
                <DisplayStarRating
                  stars={feedback.questionDifficulty}
                  size="4xl"
                />
              </div>
              <div>
                <label className="block font-bold text-gray-700">
                  Comments
                </label>
                <p className="h-[15vh] overflow-auto bg-gray-100 p-4">
                  {feedback.questionDifficultyComment}
                </p>
              </div>
            </div>
          </section>
        </div>
        <div className="mb-4">
          <section className="rounded-lg border bg-white p-6 shadow">
            <h2 className=" mb-4 text-lg font-semibold text-gray-800 lg:text-2xl">
              Overall Satisfaction
            </h2>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label className="block font-bold text-gray-700">Rating</label>
                <DisplayStarRating
                  stars={feedback.overallSatisfaction}
                  size="4xl"
                />
              </div>
              <div>
                <label className="block font-bold text-gray-700">
                  Comments
                </label>
                <p className="h-[15vh] overflow-auto bg-gray-100 p-4">
                  {feedback.overallSatisfactionComment}
                </p>
              </div>
            </div>
          </section>
        </div>
        <div className="mb-4">
          <section className="rounded-lg border bg-white p-6 shadow">
            <h2 className=" mb-4 text-lg font-semibold text-gray-800 lg:text-2xl">
              Additional Comments
            </h2>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label className="block font-bold text-gray-700">
                  Comments
                </label>
                <p className="h-[15vh] overflow-auto bg-gray-100 p-4">
                  {feedback.additionalComments}
                </p>
              </div>
            </div>
          </section>
        </div>

        {/* <div className="mb-4">
          <p className="text-lg font-semibold">Overall Experience:</p>
          <DisplayStarRating stars={feedback.overallExperience} size="4xl" />
          <p className="mt-2 text-lg font-semibold">Comments:</p>
          <p>{feedback.overallExperienceComment}</p>
        </div> */}
        {/* <div className="mb-4">
          <p className="text-lg font-semibold">Ease of Following Guidelines:</p>
          <DisplayStarRating stars={feedback.guidelinesEase} size="lg" />
          <p className="mt-2 text-lg font-semibold">Comments:</p>
          <p>{feedback.guidelinesEaseComment}</p>
        </div> */}
        {/* <div className="mb-4">
          <p className="text-lg font-semibold">Exam Checklist:</p>
          <DisplayStarRating stars={feedback.examChecklist} size="lg" />
          <p className="mt-2 text-lg font-semibold">Comments:</p>
          <p>{feedback.examChecklistComment}</p>
        </div> */}
        {/* <div className="mb-4">
          <p className="text-lg font-semibold">Exam Interface:</p>
          <DisplayStarRating stars={feedback.examInterface} size="lg" />
          <p className="mt-2 text-lg font-semibold">Comments:</p>
          <p>{feedback.examInterfaceComment}</p>
        </div> */}
        {/* <div className="mb-4">
          <p className="text-lg font-semibold">Question Difficulty:</p>
          <DisplayStarRating stars={feedback.questionDifficulty} size="lg" />
          <p className="mt-2 text-lg font-semibold">Comments:</p>
          <p>{feedback.questionDifficultyComment}</p>
        </div> */}
        {/* <div className="mb-4">
          <p className="text-lg font-semibold">Overall Satisfaction:</p>
          <DisplayStarRating stars={feedback.overallSatisfaction} size="lg" />
          <p className="mt-2 text-lg font-semibold">Comments:</p>
          <p>{feedback.overallSatisfactionComment}</p>
        </div> */}
        <button
          className="mt-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default DisplayFeedbackModal;
