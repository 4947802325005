import { useMemo } from "react";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

const CodingProblemTable = (props) => {
  const { columnsData, tableData, editClickHandler, onDeleteClickHandler } =
    props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  } = tableInstance;

  return (
    <div
      className={
        "z-10 flex h-full w-full flex-col  rounded-[20px] bg-white bg-clip-border p-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto"
      }
    >
      <header className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          All Problems
        </div>
      </header>

      <div
        className="mt-8 h-full overflow-x-scroll xl:overflow-x-hidden"
        style={{ height: "700px" }}
      >
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <div className="flex w-full justify-between pr-10 text-xs tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data;
                    if (cell.column.Header === "#") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "TITLE") {
                      data = (
                        <p className="mr-[10px] text-sm font-semibold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "DIFFICULTY") {
                      data = (
                        <p className="text-sm font-bold capitalize text-navy-700 dark:text-white">
                          {cell.value === "easy" ? (
                            <span className="mx-2  rounded-2xl bg-green-300 px-3 py-2 ">
                              Easy
                            </span>
                          ) : cell.value === "medium" ? (
                            <span className="mx-2 rounded-2xl bg-orange-300 px-3 py-2 ">
                              Medium
                            </span>
                          ) : cell.value === "hard" ? (
                            <span className="mx-2 rounded-2xl bg-red-300 px-3 py-2 ">
                              Hard
                            </span>
                          ) : (
                            <span className="mx-2 rounded-2xl bg-blue-300 px-3 py-2 ">
                              Not Specified
                            </span>
                          )}
                        </p>
                      );
                    } else if (cell.column.Header === "CREATED BY") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          <span
                            className={`mx-1 rounded-2xl border-2 ${
                              cell.value ? "bg-green-500" : "bg-blue-500"
                            }  p-2 text-white`}
                          >
                            {cell.value ? cell.value : "EngineRay"}
                          </span>
                        </p>
                      );
                    } else if (cell.column.Header === "TAGs") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value &&
                            cell.value.map((tag) => {
                              return (
                                <span className="text-black mx-1 rounded-2xl border-2  bg-gray-300 p-2">
                                  {tag}
                                </span>
                              );
                            })}
                        </p>
                      );
                    } else if (cell.column.Header === "Actions") {
                      data = (
                        <p className="space-x-3 text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value && cell.value.editQuestion && (
                            <button
                              onClick={() => {
                                editClickHandler(cell.value.editQuestion);
                              }}
                            >
                              <FaPencilAlt className="text-lg text-gray-800 dark:text-white" />
                            </button>
                          )}
                          {cell.value && cell.value.deleteQuestion && (
                            <button
                              onClick={() => {
                                onDeleteClickHandler(cell.value.deleteQuestion);
                              }}
                            >
                              <FaTrashAlt className="text-lg text-red-800 dark:text-white" />
                            </button>
                          )}
                        </p>
                      );
                    }

                    return (
                      <td
                        className="pt-[14px] pb-[20px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div>
          <span className="text-s mr-3">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>

          <span className="text-s mr-3">
            Go to page:{" "}
            <input
              type="number"
              min={1}
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              className="border-black mx-2 w-20 rounded border p-1 text-center focus:outline-none focus:ring-2 focus:ring-blue-600 dark:bg-gray-900 dark:text-white"
            />
          </span>

          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="rounded border p-1 text-xs focus:outline-none focus:ring-2 focus:ring-blue-600 dark:bg-gray-900 dark:text-white"
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>

        <div>
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="mx-1 rounded bg-blue-500 px-3 py-1 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {"<<"}
          </button>

          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="mx-1 rounded bg-blue-500 px-3 py-1 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {"<"}
          </button>

          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="mx-1 rounded bg-blue-500 px-3 py-1 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {">"}
          </button>

          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="mx-1 rounded bg-blue-500 px-3 py-1 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {">>"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CodingProblemTable;
