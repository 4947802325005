import React from "react";

const DisplayStarRating = ({ stars, size }) => {
  const totalStars = 5;

  // Define the mapping from size prop to Tailwind CSS class
  const sizeClassMap = {
    "4xl": "text-4xl",
    "2xl": "text-2xl",
    xl: "text-xl",
    lg: "text-lg",
    sm: "text-sm",
    xs: "text-xs",
  };

  // Function to render stars
  const renderStars = () => {
    let starElements = [];

    for (let i = 1; i <= totalStars; i++) {
      starElements.push(
        <span
          key={i}
          className={`${sizeClassMap[size] || "text-lg"} ${
            i <= stars ? "text-yellow-500" : "text-gray-300"
          }`}
        >
          ★
        </span>
      );
    }

    return starElements;
  };

  return <div className="flex">{renderStars()}</div>;
};

export default DisplayStarRating;
