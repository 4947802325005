/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import Card from "components/card";
import { getPlansAndLimits, updatePlanUsage } from "../../../apis";
import toastService from "services/toastService";
import { ThreeDots } from "react-loader-spinner";

const PlanConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [planAndFeatures, setPlanAndFeatures] = useState([]);
  const [updateRecords, setUpdateRecords] = useState(false);

  const formik = useFormik({
    initialValues: {},
    validate: (values) => {
      const errors = {};
      // Validate each feature input
      for (const key in values) {
        if (values[key] !== "" && isNaN(Number(values[key]))) {
          errors[key] = "Must be a number or empty for unlimited usage";
        }
      }
      return errors;
    },
    onSubmit: async (values) => {
      const featureUpdate = Object.keys(values).map((key) => ({
        featureName: key,
        limitValue: values[key] === "" ? null : Number(values[key]), // null for unlimited
      }));

      const payload = {
        planId: selectedPlanId,
        featureUpdate,
      };

      console.log("Payload :", payload);

      try {
        setLoading(true);
        const response = await updatePlanUsage(payload);

        if (response.status === 200) {
          toastService.success("Configuration Updated");

          // Update the local state with the new values to keep it consistent
          const updatedPlanAndFeatures = planAndFeatures.map((plan) => {
            if (plan.planId === selectedPlanId) {
              return {
                ...plan,
                features: plan.features.map((feature) => {
                  const updatedFeature = featureUpdate.find(
                    (f) => f.featureName === feature.feature
                  );
                  return updatedFeature
                    ? { ...feature, limitValue: updatedFeature.limitValue }
                    : feature;
                }),
              };
            }
            return plan;
          });
          setPlanAndFeatures(updatedPlanAndFeatures);
        } else {
          toastService.error("Something went wrong");
        }
      } catch (error) {
        console.log("Error :", error);
        toastService.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchPlansAndLimits = async () => {
      try {
        const response = await getPlansAndLimits();
        if (response.status === 200) {
          setPlanAndFeatures(response.data.plans);
        } else {
          toastService.error("Unable to fetch plans and features");
        }
      } catch (error) {
        console.log("Error fetching plans and features:", error);
      }
    };
    fetchPlansAndLimits();
  }, [updateRecords]);

  const updateFormValues = (planId) => {
    const selectedPlan = planAndFeatures.find((plan) => plan.planId === planId);

    const formValues = {};
    selectedPlan.features.forEach((feature) => {
      formValues[feature.feature] =
        feature.limitValue === null
          ? "" // Unlimited usage
          : feature.limitValue; // Allow 0 to be displayed
    });

    formik.setValues(formValues);
  };

  const handlePlanChange = (event) => {
    const planId = parseInt(event.target.value);
    setSelectedPlanId(planId);
    updateFormValues(planId);
  };

  return (
    <div>
      <div
        className={
          "z-10 flex h-full w-full flex-col  rounded-[20px] bg-white bg-clip-border p-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto"
        }
      >
        <div className="relative flex items-center ">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {"Plan Configuration"}
          </div>
        </div>
        <div className="min-w-screen flex items-center justify-center rounded-xl bg-gray-100 p-1">
          <form
            onSubmit={formik.handleSubmit}
            className="w-full flex-col items-center space-y-5 rounded-xl bg-white p-8 shadow-lg"
          >
            <div>
              <label
                htmlFor="planSelect"
                className="block text-sm font-medium text-gray-700"
              >
                Select Plan
              </label>
              <select
                id="planSelect"
                name="planSelect"
                className="mt-1 block w-full rounded-md border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={handlePlanChange}
                value={selectedPlanId || ""}
              >
                <option value="" label="Select plan" />
                {planAndFeatures.map((plan) => (
                  <option key={plan.planId} value={plan.planId}>
                    {plan.planName}
                  </option>
                ))}
              </select>
            </div>

            {selectedPlanId && (
              <>
                {planAndFeatures
                  .find((plan) => plan.planId === selectedPlanId)
                  .features.map((feature) => (
                    <div key={feature.feature}>
                      <label
                        htmlFor={feature.feature}
                        className="block text-sm font-medium text-gray-700"
                      >
                        {feature.featureName}
                      </label>
                      <input
                        id={feature.feature}
                        name={feature.feature}
                        type="number"
                        min={0}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 ${
                          formik.errors[feature.feature] &&
                          formik.touched[feature.feature]
                            ? "border-red-500"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values[feature.feature] !== undefined
                            ? formik.values[feature.feature]
                            : ""
                        }
                        placeholder="Enter limit (0 = Not allowed, leave empty for unlimited)"
                      />
                      {formik.errors[feature.feature] &&
                      formik.touched[feature.feature] ? (
                        <div className="mt-1 text-sm text-red-500">
                          {formik.errors[feature.feature]}
                        </div>
                      ) : null}
                    </div>
                  ))}
              </>
            )}

            <div>
              <button
                type="submit"
                className={`mt-5 rounded-md bg-blue-500 py-2 px-4 text-white transition duration-200 hover:bg-blue-600 ${
                  Object.values(formik.errors).length ||
                  !selectedPlanId ||
                  loading
                    ? " cursor-not-allowed opacity-60"
                    : " "
                }`}
                disabled={
                  Object.values(formik.errors).length ||
                  !selectedPlanId ||
                  loading
                }
              >
                <div className="flex flex-row items-center justify-center gap-5">
                  <span>{loading ? "Submitting..." : "Submit"}</span>
                  <span>
                    <ThreeDots
                      height="30"
                      width="30"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={loading}
                    />
                  </span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PlanConfiguration;
