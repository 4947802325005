/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";

import Card from "components/card";
import { getAllCodingProblems, deleteEnginerayCodingQuestion } from "apis";
import toastService from "services/toastService";
import { ThreeDots } from "react-loader-spinner";
import CodingProblemTable from "./components/CodingProblemTable";
import { codingProblemTableColumns } from "./variables/columnsData";
import CodingProblemCreateModal from "./components/CodingProblemCreateModal";

const CodingProblems = () => {
  //   const fileInputRef = useRef(null);

  const [loading, setLoading] = useState(false);
  //   const [loadingGenerateQuestions, setLoadingGenerateQuestions] =
  //     useState(false);
  const [codingProblemRecords, setCodingProblemRecords] = useState([]);
  const [updateRecords, setUpdateRecords] = useState(false);
  const [uploadCodingProblemModalOpen, setUploadCodingProblemModalOpen] =
    useState(false);
  const [editRecord, setEditRecord] = useState(null);

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const response = await getAllCodingProblems();
        setCodingProblemRecords(response.data.codingQuestions);
      } catch (error) {
        console.log("error ");
      }
    })();
  }, [refresh]);

  // const handleOpenModal = () => {
  //   setViewModal(true);
  // };
  // const handleCloseModal = () => {
  //   setViewModal(false);
  // };

  //   const clearFile = () => {
  //     formik.setFieldValue("csvFile", null);

  // // Use the ref to clear the file input's display value
  //     if (fileInputRef.current) {
  //       fileInputRef.current.value = "";
  //     }
  //   };
  //   const clearFileImage = () => {
  //     formik.setFieldValue("skillLogo", null);

  //     // Use the ref to clear the file input's display value
  //     if (imageFileInputRef.current) {
  //       imageFileInputRef.current.value = "";
  //     }
  //   };

  // function toggleUpdateRecords() {
  //   const newState = !updateRecords;
  //   setUpdateRecords(newState);
  // }

  const onEditProblemClickHandler = async (problemId) => {
    console.log("problem id:", problemId);
    const problemToEdit = codingProblemRecords.find(
      (skill) => skill.id === problemId
    );
    if (problemToEdit) {
      console.log("Skill to edit:", problemToEdit);
      setEditRecord(problemToEdit);
      // Further logic to edit the skill
      setUploadCodingProblemModalOpen(true);
    } else {
      console.log("Skill not found");
    }
  };

  //   const validate = (values) => {
  //     const errors = {};
  //     if (!values.skillName) {
  //       errors.skillName = "Required";
  //     }
  //     if (!values.questionType) {
  //       errors.questionType = "Required";
  //     }

  //     return errors;
  //   };

  //   const validate_1 = (values) => {
  //     const errors = {};
  //     if (!values.topicName) {
  //       errors.topicName = "Required";
  //     }
  //     if (!values.numberOfQuestions && values.numberOfQuestions !== 0) {
  //       errors.numberOfQuestions = "Required";
  //     }
  //     if (values.numberOfQuestions < 1) {
  //       errors.numberOfQuestions = "Minimum number of questions is 1 ";
  //     }
  //     if (!values.questionType) {
  //       errors.questionType = "Required";
  //     }

  //     return errors;
  //   };
  //   const formik = useFormik({
  //     initialValues: {
  //       skillName: "",
  //       csvFile: null,
  //       skillLogo: null,
  //       questionType: "",
  //     },
  //     validate,
  //     onSubmit: async (values) => {
  //       console.log("Form values:", values);

  //       // Here you can handle the file data further, e.g., uploading to a server
  //       const file = values.csvFile;
  //       const skillLogo = values.skillLogo;
  //       const questionType = values.questionType;

  //       console.log("Question Type :", questionType);

  //       let payload = {
  //         title: values.skillName,
  //       };

  //       if (file) {
  //         console.log(`Uploaded file name: ${file.name}`);
  //         payload = {
  //           ...payload,
  //           csvData: file,
  //         };
  //       }

  //       if (questionType === "mcq") {
  //         if (skillLogo) {
  //           console.log(`Skill logo name: ${skillLogo.name}`);
  //           payload = {
  //             ...payload,
  //             skillLogo: skillLogo,
  //           };
  //         }

  //         setLoading(true);
  //         console.log("Pay load ", payload);

  //         const response = await addNewSkillWithQuestions(payload);

  //         if (response.status === 201) {
  //           toastService.success("Skill Added");
  //           toggleUpdateRecords();
  //         } else if (response.status === 409) {
  //           toastService.warning("Skill Already Exists");
  //         } else {
  //           toastService.error(response.data.message);
  //         }
  //         setLoading(false);
  //         formik.resetForm();
  //         clearFile();
  //         clearFileImage();
  //       } else {
  //         setLoading(true);
  //         console.log("Pay load ", payload);

  //         const response = await addNewSkillWithQuestionsOpenEnded(payload);

  //         if (response.status === 201) {
  //           toastService.success("Skill Added");
  //           toggleUpdateRecords();
  //         } else if (response.status === 409) {
  //           toastService.warning("Skill Already Exists");
  //         } else {
  //           toastService.error(response.data.message);
  //         }
  //         setLoading(false);
  //         formik.resetForm();
  //         clearFile();
  //         clearFileImage();
  //       }
  //     },
  //   });

  //   const formikGenerateQuestions = useFormik({
  //     initialValues: {
  //       topicName: "",
  //       numberOfQuestions: 100,
  //       questionType: "", // initial value for radio buttons
  //     },
  //     validate: validate_1,
  //     onSubmit: async (values) => {
  //       console.log("Form values:", values);

  //       // Here you can handle the file data further, e.g., uploading to a server

  //       let payload;

  //       payload = {
  //         topicName: values.topicName,
  //         numberOfQuestions: values.numberOfQuestions,
  //         questionType: values.questionType,
  //       };

  //       setLoadingGenerateQuestions(true);
  //       console.log("Pay load ", payload);

  //       const response = await addNewSkillTopicGenerator(payload);

  //       if (response.status === 200) {
  //         toastService.success("Request Submitted.");
  //         //toggleUpdateRecords();
  //       } else if (response.status === 409) {
  //         toastService.warning("Skill Already Exists");
  //       } else {
  //         toastService.error("Something went wrong");
  //       }
  //       setLoadingGenerateQuestions(false);
  //       formikGenerateQuestions.resetForm();
  //     },
  //   });

  const deleteCodingQuestion = async (questionId) => {
    console.log("Question to delete :", questionId);

    let payload;

    payload = {
      questionId: questionId,
    };

    console.log("Pay load ", payload);

    const response = await deleteEnginerayCodingQuestion(payload);

    if (response.status === 200) {
      toastService.success("Question deleted");
      setRefresh((prevState) => !prevState);
    } else if (response.status === 404) {
      toastService.warning("Question not found");
    } else {
      toastService.info(response.data.message);
    }
  };

  return (
    <div>
      <div
        className={
          "z-10 flex h-full w-full flex-col  rounded-[20px] bg-white bg-clip-border p-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto"
        }
      >
        <div className="relative flex items-center ">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {"Add Coding Problem"}
          </div>
        </div>
        <div className="min-w-screen flex items-center justify-center rounded-xl bg-gray-100 p-1">
          <div className="flex w-full items-center space-x-6 rounded-xl bg-white p-8 shadow-lg">
            {/* Submit Button */}
            <div className="">
              <button
                onClick={() => {
                  setUploadCodingProblemModalOpen(true);
                }}
                type="submit"
                className={`mt-5 rounded-md bg-blue-500 py-2 px-4 text-white transition duration-200 hover:bg-blue-600 ${
                  loading ? " cursor-not-allowed opacity-60" : " "
                }`}
                disabled={loading}
              >
                <div className="flex flex-row items-center justify-center gap-5">
                  <span>
                    {loading ? "Submitting..." : " + Upload Coding Problem"}
                  </span>
                  <span>
                    <ThreeDots
                      height="30"
                      width="30"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={loading}
                    />
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <CodingProblemTable
        columnsData={codingProblemTableColumns}
        tableData={codingProblemRecords}
        editClickHandler={onEditProblemClickHandler}
        onDeleteClickHandler={deleteCodingQuestion}
      />
      <CodingProblemCreateModal
        setRefresh={setRefresh}
        uploadCustomCodingProblemModalOpen={uploadCodingProblemModalOpen}
        setUploadCustomCodingProblemModalOpen={setUploadCodingProblemModalOpen}
      />

      {/* {viewModal && (
        <SkillEditModal
          skill={editRecord}
          isOpen={viewModal}
          onClose={handleCloseModal}
          refreshSkills={toggleUpdateRecords}
        />
      )} */}
    </div>
  );
};

export default CodingProblems;
