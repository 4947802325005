export const supportTicketColumns = [
  {
    Header: "USER",
    accessor: "name",
  },
  {
    Header: "USER TYPE",
    accessor: "userType",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "DESCRIPTION",
    accessor: "message",
  },
  {
    Header: "MEDIA",
    accessor: "images",
  },
  {
    Header: "DATE",
    accessor: "createdAt",
  },
];
