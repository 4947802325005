import * as Yup from "yup";

const examVettingUpdateSchema = Yup.object({
  idCheckHuman: Yup.boolean().required("Identity Verification is required"),
  roomTourHuman: Yup.boolean().required("Room Tour Verification is required"),
  humanStatus: Yup.boolean().required("Overall Result  is required"),
  vettingComments: Yup.string().optional(),
  startTime: Yup.string().matches(
    /^\d{2,3}:\d{2}$/,
    "Invalid time format. Please use MM:SS format."
  ),
  endTime: Yup.string().matches(
    /^\d{2,3}:\d{2}$/,
    "Invalid time format. Please use MM:SS format."
  ),
});

export default examVettingUpdateSchema;
