import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "", //19 -- 24
  role: "",
};

export const identifierSlice = createSlice({
  name: "identifier",
  initialState,
  reducers: {
    setId: (state, action) => {
      state.id = action.payload.id;
    },
    setRole: (state, action) => {
      state.role = action.payload.role;
    },
    resetIdentifier: (state) => {
      state.id = "";
      state.role = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const identifierActions = identifierSlice.actions;

export default identifierSlice.reducer;
