import { videoVettingCloumns } from "./variables/columnsData";
import ComplexTable from "./components/ComplexTable";
import { useEffect, useState } from "react";
import { getAllVettingVideos, getVettingRecord, deleteExam } from "apis";

import MyModal from "components/modal/ModalCustom";
import VettingRecordModal from "components/modal/VettingRecordModal";
import toastService from "services/toastService";

const Tables = () => {
  const [showModal, setShowModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [vettingVideoRecord, setVettingVideoRecord] = useState([]);
  const [updateRecords, setUpdateRecords] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleOpenViewModal = () => {
    setViewModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseViewModal = () => {
    setViewModal(false);
  };
  const [vettingRecords, setVettingRecords] = useState([]);

  const onVideoClickHandler = async (recordId) => {
    console.log("Record id :", recordId);

    const response = await getVettingRecord(recordId);
    if (response.status === 200) {
      console.log("Record : ", response.data.record);
      setVettingVideoRecord(response.data.record);
      handleOpenModal();
    } else {
      console.log("Error ");
    }
  };

  const onViewVettingRecordClickHandler = async (recordId) => {
    console.log("Record id :", recordId);

    const response = await getVettingRecord(recordId);
    if (response.status === 200) {
      console.log("Record : ", response.data.record);
      setVettingVideoRecord(response.data.record);
      handleOpenViewModal();
    } else {
      console.log("Error ");
    }
  };

  const onDeleteExamClickHandler = async (recordId) => {
    console.log("Record id :", recordId);
    const payload = {
      recordId: recordId,
    };
    const response = await deleteExam(payload);
    if (response.status === 200) {
      toastService.success("Exam deleted");
      toggleUpdateRecords();
    } else {
      console.log("Error ");
    }
  };

  function toggleUpdateRecords() {
    const newState = !updateRecords;
    setUpdateRecords(newState);
  }
  useEffect(() => {
    (async () => {
      try {
        const response = await getAllVettingVideos();
        setVettingRecords(response.data.records);
      } catch (error) {
        console.log("error ");
      }
    })();
  }, [updateRecords]);

  return (
    <div>
      <ComplexTable
        columnsData={videoVettingCloumns}
        tableData={vettingRecords}
        tableTittle={"Videos"}
        onVideoClick={onVideoClickHandler}
        onViewVettingRecordClick={onViewVettingRecordClickHandler}
        onDeleteExamClickHandler={onDeleteExamClickHandler}
      />

      {showModal && (
        <MyModal
          vettingVideoRecord={vettingVideoRecord}
          handleCloseModal={handleCloseModal}
          toggleUpdateRecords={toggleUpdateRecords}
          isUniversityRecord={false}
        />
      )}
      {viewModal && (
        <VettingRecordModal
          vettingVideoRecord={vettingVideoRecord}
          handleCloseModal={handleCloseViewModal}
        />
      )}
    </div>
  );
};

export default Tables;
