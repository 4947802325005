export const skillTableColumns = [
  {
    Header: "#",
    accessor: "idx",
  },
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "QUESTION COUNT",
    accessor: "questionCount",
  },
  {
    Header: "CREATED BY",
    accessor: "company",
  },
  {
    Header: "TAGs",
    accessor: "tags",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
