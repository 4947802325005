import React, { useState } from "react";

const TableCellContent = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (text.length <= maxLength || isExpanded) {
    return (
      <span
        className="cursor-pointer  "
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {text}
      </span>
    );
  }

  return (
    <span
      className="cursor-pointer text-blue-600 underline"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      {text.substring(0, maxLength)}...
    </span>
  );
};

export default TableCellContent;
