export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "APPLICANT",
    accessor: "applicant",
    canGroupBy: true, // <-- Allows the column to be grouped
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const videoVettingCloumns = [
  {
    Header: "APPLICANT",
    accessor: "applicant",
  },
  {
    Header: "SKILL",
    accessor: "skill",
    aggregate: "count",
    Aggregated: ({ value }) => `${value} Skills`,
  },
  {
    Header: "AI VETTING",
    accessor: "aiVetting",
  },

  {
    Header: "MANUAL VETTING",
    accessor: "humanSTATUS",
  },
  {
    Header: "ID CHECK",
    accessor: "idCheck",
  },
  {
    Header: "DATE",
    accessor: "date",
  },

  {
    Header: "VETTING DETAILS",
    accessor: "actions",
  },
];
