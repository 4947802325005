import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";

import * as Select from "@radix-ui/react-select";
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { createCodingProblemEngineray } from "apis";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import TestCaseTabs from "./TestCaseTabs";
import "./modal.css";
import toastService from "services/toastService";
import DOMPurify from "dompurify";
const initialInputField = () => ({ input: "", type: "string" });
const initialOutputField = () => ({ output: "", type: "string" });

const initialTabData = () => ({
  inputFields: [initialInputField()],
  outputFields: initialOutputField(),
});

const CodingProblemCreateModal = ({
  uploadCustomCodingProblemModalOpen,
  setUploadCustomCodingProblemModalOpen,
  setRefresh,
}) => {
  const [problemTitle, setProblemTitle] = useState("");
  const [problemDifficulty, setProblemDifficulty] = useState("");
  const [problemDescription, setProblemDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [testCases, setTestCases] = useState([initialTabData()]);
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const validateInput = () => {
    const newErrors = {};
    // Existing validations
    if (!problemTitle) newErrors.problemTitle = "Problem title is required";
    if (!problemDifficulty)
      newErrors.problemDifficulty = "Please select a difficulty";
    if (!problemDescription)
      newErrors.problemDescription = "Description is required";
    if (tags.length > 4) newErrors.tags = "You can only add up to 4 tags";

    // Test Cases Validations
    if (testCases.length === 0) {
      newErrors.testCases = "At least one test case is required";
    } else {
      testCases.forEach((testCase, index) => {
        // Check that every inputField has non-empty input and type after trimming
        const inputFieldsValid = testCase.inputFields.every(
          (field) => field.input.trim() !== "" && field.type.trim() !== ""
        );
        if (!inputFieldsValid) {
          newErrors[
            `testCase_${index}_inputFields`
          ] = `All input fields in test case ${index + 1} must be non-empty`;
        }

        // Check that the outputFields are non-empty after trimming
        const outputFieldValid =
          testCase.outputFields.output.trim() !== "" &&
          testCase.outputFields.type.trim() !== "";
        if (!outputFieldValid) {
          newErrors[
            `testCase_${index}_outputFields`
          ] = `Output field in test case ${index + 1} must be non-empty`;
        }
      });
    }

    // Update the form validity state
    const formIsValid = Object.keys(newErrors).length === 0;
    setIsFormValid(formIsValid);

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      // Form is valid, proceed with submission
      console.log("Problem Title : ", problemTitle);
      console.log("Problem Difficulty : ", problemDifficulty);
      console.log("Problem Tags : ", tags);
      console.log("Problem Description : ", problemDescription);
      console.log("Problem Description TYPE : ", typeof problemDescription);
      console.log("Problem TestCases : ", testCases);
      console.log("Form is valid, proceed with submission");
      const sanitizedDescription = DOMPurify.sanitize(problemDescription);
      const payload = {
        problemTitle: problemTitle,
        problemDifficulty: problemDifficulty,
        problemDescription: sanitizedDescription,
        tags: tags,
        testCases: testCases,
      };

      try {
        // Update the call to include `page` and `pageSize`
        const response = await createCodingProblemEngineray(payload);

        // Assuming the API returns the MCQs in a structure like { data: { mcqs: [] } }
        // You might need to adjust based on the actual API response
        if (response.status === 201) {
          toastService.info("Problem created");
          setRefresh((prevState) => !prevState);
          setUploadCustomCodingProblemModalOpen(false);
        } else {
          toastService.error("Oops Something went wrong.");
        }
      } catch (error) {
        console.error(error);
        toastService.error("Something went wrong");
      } finally {
      }

      // Add your submit logic here
    }
  };
  useEffect(() => {
    validateInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [problemTitle, problemDifficulty, problemDescription, tags, testCases]);

  const SelectItem = React.forwardRef(
    ({ value, children, ...props }, forwardedRef) => (
      <Select.Item
        {...props}
        value={value}
        ref={forwardedRef}
        onSelect={() => setProblemDifficulty(value)}
        className={`text-violet11 data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 relative flex h-[25px] select-none items-center rounded-[3px] pr-[35px] pl-[25px] text-[13px] leading-none`}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="absolute left-0 inline-flex w-[25px] items-center justify-center">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    )
  );
  return (
    <Dialog.Root
      open={uploadCustomCodingProblemModalOpen}
      onOpenChange={setUploadCustomCodingProblemModalOpen}
      modal={true}
    >
      <Dialog.Portal>
        <Dialog.Overlay className="   DialogOverlay bg-black/50 data-[state=open]:animate-overlayShow fixed inset-0 " />
        <Dialog.Content className="DialogContent data-[state=open]:animate-contentShow  fixed top-[50%] left-[50%] max-h-[75vh] w-[45vw] max-w-[90vw] translate-x-[-50%] translate-y-[-50%] overflow-y-auto rounded-[6px] bg-white py-6 px-6 shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <Dialog.Title className="m-0 text-[17px] font-bold text-navy-600">
            Upload custom coding problem
          </Dialog.Title>
          <Dialog.Description className="mt-[10px] mb-5 text-[15px] leading-normal text-navy-600">
            Upload your own custom coding problem..
          </Dialog.Description>

          <div className="mb-4 flex flex-col gap-5">
            <div>
              <label
                className="mb-2 block text-sm  font-semibold text-navy-600"
                htmlFor="exp"
              >
                Problem Title
              </label>
              <input
                className={`focus:shadow-outline w-full appearance-none rounded border py-2 px-3 text-sm leading-tight text-navy-700 shadow focus:outline-none`}
                id="search"
                type="text"
                value={problemTitle}
                onChange={(e) => setProblemTitle(e.target.value)}
                placeholder="Enter a descriptive title for problem"
              />
              <div>
                {errors.problemTitle && (
                  <p className="mt-2 text-xs text-red-500">
                    {errors.problemTitle}
                  </p>
                )}
                {/* Other error messages similarly */}
              </div>
            </div>
            <div className="w-[30%]">
              <label
                className="mb-2 block text-sm  font-semibold text-navy-600"
                htmlFor="difficulty"
              >
                Difficulty
              </label>
              <Select.Root
                name="difficulty"
                value={problemDifficulty}
                onValueChange={(value) => setProblemDifficulty(value)}
              >
                <Select.Trigger
                  className="shadow-black/10 data-[placeholder]:text-navy-500 inline-flex w-full cursor-pointer items-center justify-between gap-2 rounded border bg-white px-4 py-3 text-sm leading-none text-navy-600 shadow-[0_2px_10px] outline-none hover:bg-gray-50"
                  aria-label="Job Type"
                >
                  <Select.Value placeholder="Select problem difficulty..." />
                  <Select.Icon>
                    <ChevronDownIcon />
                  </Select.Icon>
                </Select.Trigger>
                <Select.Portal>
                  <Select.Content className="overflow-hidden rounded-md bg-white shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
                    <Select.Viewport className="p-[5px]">
                      <SelectItem value="easy">Easy</SelectItem>
                      <SelectItem value="medium">Medium</SelectItem>
                      <SelectItem value="hard">Hard</SelectItem>
                    </Select.Viewport>
                  </Select.Content>
                </Select.Portal>
              </Select.Root>
              {errors.problemDifficulty && (
                <p className="mt-2 text-xs text-red-500">
                  {errors.problemDifficulty}
                </p>
              )}
            </div>
            <div>
              <label
                className="mb-2 block text-sm  font-semibold text-navy-600"
                htmlFor="exp"
              >
                Tags
              </label>
              <TagsInput
                value={tags}
                onChange={setTags}
                maxTags={4}
                tagProps={{
                  className:
                    " react-tagsinput-tag bg-blue-100 border border-blue-200 text-navy-600",
                  classNameRemove: "react-tagsinput-remove",
                }}
                inputProps={{
                  className: "react-tagsinput-input  w-[20%]",
                  placeholder: "Add a tag",
                }}
                focusedClassName="border border-blue-200"
              />
              {errors.tags && (
                <p className="mt-2 text-xs text-red-500">{errors.tags}</p>
              )}
            </div>
            <div className="">
              <label
                className="mb-2 block text-sm  font-semibold text-navy-600"
                htmlFor="description"
              >
                Problem Description
              </label>
              <ReactQuill
                theme="snow"
                value={problemDescription}
                onChange={setProblemDescription}
                className="ql-editor p-0 "
              />
              {errors.problemDescription && (
                <p className="mt-2 text-xs text-red-500">
                  {errors.problemDescription}
                </p>
              )}
            </div>
            <div className="">
              <label
                className="mb-2 block text-sm  font-semibold text-navy-600"
                htmlFor="description"
              >
                Test Cases
              </label>
              <div className="p-2">
                <TestCaseTabs
                  tabs={testCases}
                  setTabs={setTestCases}
                  errors={errors}
                />
              </div>
              {errors.testCases && (
                <p className="mt-2 text-xs text-red-500">{errors.testCases}</p>
              )}
            </div>
          </div>

          <div className="mt-[25px] flex justify-end">
            <button
              type="submit"
              disabled={!isFormValid}
              onClick={handleSubmit}
              className="rounded bg-blue-500 py-2 px-4 font-semibold text-white hover:bg-blue-600 disabled:bg-blue-400"
            >
              Upload Problem
            </button>
          </div>
          <Dialog.Close asChild>
            <button
              className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
              aria-label="Close"
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default CodingProblemCreateModal;
