export const questionTableColumns = [
  {
    Header: "#",
    accessor: "idx",
  },
  {
    Header: "SKILL",
    accessor: "section",
  },
  {
    Header: "DIFFICULTY",
    accessor: "difficulty",
  },
  {
    Header: "QUESTION",
    accessor: "text",
  },
];
