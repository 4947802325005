import React, { useState } from "react";
import { TiPlus } from "react-icons/ti";
import { FaCircleXmark } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
const initialInputField = () => ({ input: "", type: "string" });
const initialOutputField = () => ({ output: "", type: "string" });

const initialTabData = () => ({
  inputFields: [initialInputField()],
  outputFields: initialOutputField(),
});

function TestCaseTabs({ tabs, setTabs, errors }) {
  // const [tabs, setTabs] = useState([initialTabData()]);
  const [activeTab, setActiveTab] = useState(0);

  const addInputField = (tabIndex) => {
    const newTabs = [...tabs];
    newTabs[tabIndex].inputFields.push(initialInputField());
    setTabs(newTabs);
  };

  const removeInputField = (tabIndex, fieldIndex) => {
    const newTabs = [...tabs];
    // Remove the field only if more than one input field exists
    if (newTabs[tabIndex].inputFields.length > 1) {
      newTabs[tabIndex].inputFields.splice(fieldIndex, 1);
      setTabs(newTabs);
    }
  };

  const handleInputChange = (tabIndex, fieldIndex, value, isType = false) => {
    const newTabs = [...tabs];
    if (isType) {
      newTabs[tabIndex].inputFields[fieldIndex].type = value;
    } else {
      newTabs[tabIndex].inputFields[fieldIndex].input = value;
    }
    setTabs(newTabs);
  };

  const handleOutputChange = (tabIndex, value, isType = false) => {
    const newTabs = [...tabs];
    if (isType) {
      newTabs[tabIndex].outputFields.type = value;
    } else {
      newTabs[tabIndex].outputFields.output = value;
    }
    setTabs(newTabs);
  };

  const addTab = () => {
    if (
      tabs.length < 10 &&
      tabs[activeTab].inputFields.every((field) => field.input.trim() !== "") &&
      tabs[activeTab].outputFields.output.trim() !== ""
    ) {
      setTabs([...tabs, initialTabData()]);
      setActiveTab(tabs.length);
    } else {
      console.error("Please fill in all fields before adding a new tab.");
    }
    console.log("Tabs data: ", tabs);
  };

  const removeTab = (index) => {
    if (tabs.length > 1) {
      const newTabs = tabs.filter((_, tabIndex) => tabIndex !== index);
      setTabs(newTabs);
      setActiveTab((prevActiveTab) =>
        prevActiveTab > 0 ? prevActiveTab - 1 : 0
      );
    }
  };

  const renderTabContent = (tab, tabIndex, error) => {
    return (
      <div
        className={`tab-content ${activeTab === tabIndex ? "block" : "hidden"}`}
        key={`tab-content-${tabIndex}`}
      >
        <div>
          <h3 className="font-bold">Input Fields</h3>

          <div className="flex justify-end">
            <button
              onClick={() => addInputField(tabIndex)}
              className="focus:shadow-outline mt-2 mb-4 rounded bg-blue-500 py-2 px-4 text-sm font-bold text-white hover:bg-blue-700 focus:outline-none"
            >
              Add Input
            </button>
          </div>
          {tab.inputFields.map((field, fieldIndex) => (
            <div
              key={`input-${tabIndex}-${fieldIndex}`}
              className="mb-4 flex items-end"
            >
              <div>
                <label
                  className="mb-2 block text-sm  font-semibold text-gray-600"
                  htmlFor="difficulty"
                >
                  Input
                </label>
                <input
                  type="text"
                  value={field.input}
                  placeholder={`Input ${fieldIndex + 1}`}
                  onChange={(e) =>
                    handleInputChange(tabIndex, fieldIndex, e.target.value)
                  }
                  className="focus:shadow-outline mr-2 appearance-none rounded  border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                />
              </div>
              <div>
                <label
                  className="mb-2 block text-sm  font-semibold text-gray-600"
                  htmlFor="difficulty"
                >
                  Input type
                </label>
                <select
                  value={field.type}
                  onChange={(e) =>
                    handleInputChange(
                      tabIndex,
                      fieldIndex,
                      e.target.value,
                      true
                    )
                  }
                  className="focus:shadow-outline mr-2 w-fit rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                >
                  <option value="string">String</option>
                  <option value="int">Int</option>
                  <option value="float">Float</option>
                  <option value="array">Array</option>
                  <option value="boolean">Boolean</option>
                </select>
              </div>

              {/* Show "-" button for all input fields except the first one in each tab */}
              {fieldIndex !== 0 && (
                // <button
                //     onClick={() =>
                //         removeInputField(tabIndex, fieldIndex)
                //     }
                //     className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                // >
                //     -
                // </button>
                <button
                  data-tooltip-id="skill-remove"
                  data-tooltip-content="Click to remove skill."
                  className=" mb-2 ml-2 rounded-full p-1 text-lg text-red-400"
                  onClick={() => removeInputField(tabIndex, fieldIndex)}
                >
                  <FaCircleXmark />
                </button>
              )}
            </div>
          ))}
          {errors[`testCase_${tabIndex}_inputFields`] && (
            <p className="mb-2 text-xs text-red-500">
              {errors[`testCase_${tabIndex}_inputFields`]}
            </p>
          )}
        </div>
        <div>
          <h3 className="font-bold">Expected Output</h3>

          <div className="my-4 flex items-center gap-2">
            <div>
              <label
                className="mb-2 block text-sm  font-semibold text-gray-600"
                htmlFor="difficulty"
              >
                Output
              </label>
              <input
                type="text"
                placeholder="Output"
                value={tab.outputFields.output}
                onChange={(e) => handleOutputChange(tabIndex, e.target.value)}
                className="focus:shadow-outline mr-2 w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
              />
            </div>
            <div>
              <label
                className="mb-2 block text-sm  font-semibold text-gray-600"
                htmlFor="difficulty"
              >
                Output type
              </label>
              <select
                value={tab.outputFields.type}
                onChange={(e) =>
                  handleOutputChange(tabIndex, e.target.value, true)
                }
                className="focus:shadow-outline rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
              >
                <option value="string">String</option>
                <option value="int">Int</option>
                <option value="float">Float</option>
                <option value="array">Array</option>
                <option value="boolean">Boolean</option>
              </select>
            </div>
          </div>
          {errors[`testCase_${tabIndex}_outputFields`] && (
            <p className="mb-2 text-xs text-red-500">
              {errors[`testCase_${tabIndex}_outputFields`]}
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="tabs-container">
      <div className="tabs mb-4 flex flex-wrap items-center ">
        {tabs.map((_, tabIndex) => (
          <button
            key={`tab-${tabIndex}`}
            onClick={() => setActiveTab(tabIndex)}
            className={`tab mr-2 mt-3 text-sm ${
              activeTab === tabIndex ? "bg-blue-100" : "bg-gray-200"
            } rounded-xl px-2 py-1`}
          >
            Case {tabIndex + 1}
            {tabIndex !== 0 && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  removeTab(tabIndex);
                }}
                className="close-tab ml-2 cursor-pointer text-red-500"
              >
                &times;
              </span>
            )}
          </button>
        ))}
        <button
          disabled={
            !(
              tabs.length < 10 &&
              tabs[activeTab].inputFields.every(
                (field) => field.input.trim() !== ""
              ) &&
              tabs[activeTab].outputFields.output.trim() !== ""
            )
          }
          onClick={addTab}
          data-tooltip-id="add-testcase"
          data-tooltip-content="Click to add another testcase."
          className=" mt-3 flex cursor-pointer items-center justify-center rounded-full border border-gray-600 bg-gray-50 text-xl text-gray-600  hover:text-blue-500 disabled:cursor-default  disabled:text-gray-600"
        >
          <TiPlus />
        </button>
        {tabs.length < 10 &&
          tabs[activeTab].inputFields.every(
            (field) => field.input.trim() !== ""
          ) &&
          tabs[activeTab].outputFields.output.trim() !== "" && (
            <Tooltip id="add-testcase" />
          )}
        {/* <button
                    onClick={addTab}
                    className="add-tab bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                ></button> */}
      </div>
      {tabs.map((tab, tabIndex) => renderTabContent(tab, tabIndex))}
    </div>
  );
}

export default TestCaseTabs;
