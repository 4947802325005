import { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import { ThreeDots } from "react-loader-spinner";
import toastService from "services/toastService";
import {
  getEnginerayConfiguration,
  updateEnginerayExamConfiguration,
} from "../../../apis";

const EnginerayConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [examDurationDomain, setExamDurationDomain] = useState();
  const [examDurationCoding, setExamDurationCoding] = useState();
  const [examNumberOfQuestionsDomain, setExamNumberOfQuestionsDomain] =
    useState();

  const [codingEasyQuestions, setCodingEasyQuestions] = useState();
  const [codingMediumQuestions, setCodingMediumQuestions] = useState();
  const [updateRecords, setUpdateRecords] = useState(false);
  const validate = (values) => {
    const errors = {};
    if (!values.examDurationDomain) {
      errors.examDurationDomain = "Required";
    }
    if (!values.examNumberOfQuestionsDomain) {
      errors.examNumberOfQuestionsDomain = "Required";
    }
    if (!values.examDurationCoding) {
      errors.examDurationCoding = "Required";
    }
    if (!values.CodingExamEasyQuestions) {
      errors.CodingExamEasyQuestions = "Required";
    }
    if (!values.CodingExamMediumQuestions) {
      errors.CodingExamMediumQuestions = "Required";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      examDurationDomain: examDurationDomain || "", // Add a fallback value if examDurationDomain is undefined
      examNumberOfQuestionsDomain: examNumberOfQuestionsDomain || "", // Add a fallback value if examNumberOfQuestionsDomain is undefined
      examDurationCoding: examDurationCoding || "", // Add a fallback value if examDurationCoding is undefined
      CodingExamEasyQuestions: codingEasyQuestions || "", // Add a fallback value if CodingExamEasyQuestions is undefined
      CodingExamMediumQuestions: codingMediumQuestions || "", // Add a fallback value if CodingExamMediumQuestions is undefined
    },
    validate,
    onSubmit: async (values) => {
      console.log("Form values:", values);

      // Here you can handle the file data further, e.g., uploading to a server

      let payload;

      payload = {
        examDurationDomain: values.examDurationDomain,
        examNumberOfQuestionsDomain: values.examNumberOfQuestionsDomain,
        examDurationCoding: values.examDurationCoding,
        CodingExamEasyQuestions: values.CodingExamEasyQuestions,
        CodingExamMediumQuestions: values.CodingExamMediumQuestions,
      };

      setLoading(true);
      console.log("Pay load ", payload);

      const response = await updateEnginerayExamConfiguration(payload);

      if (response.status === 200) {
        toastService.success("configuration Updated");
        toggleUpdateRecords();
      } else {
        toastService.error("Something went wrong");
      }
      setLoading(false);
      formik.resetForm();
    },
  });

  const setFormValues = useCallback(
    (data) => {
      formik.setValues({
        examDurationDomain: data.examDurationDomain || "",
        examNumberOfQuestionsDomain: data.examNumberOfQuestionsDomain || "",
        examDurationCoding: data.examDurationCoding || "",
        CodingExamEasyQuestions: data.CodingExamEasyQuestions || "",
        CodingExamMediumQuestions: data.CodingExamMediumQuestions || "",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.setValues]
  ); // Include 'formik' in the dependency array

  useEffect(() => {
    (async () => {
      try {
        const response = await getEnginerayConfiguration();
        console.log("Response :", response);
        setExamDurationDomain(response.data.configuration.examDurationDomain);
        setExamDurationCoding(response.data.configuration.examDurationCoding);
        setExamNumberOfQuestionsDomain(
          response.data.configuration.examNumberOfQuestionsDomain
        );
        setCodingEasyQuestions(
          response.data.configuration.CodingExamEasyQuestions
        );
        setCodingMediumQuestions(
          response.data.configuration.CodingExamMediumQuestions
        );

        setFormValues(response.data.configuration);
      } catch (error) {
        console.log("error ");
      }
    })();
  }, [updateRecords, setFormValues]);

  function toggleUpdateRecords() {
    const newState = !updateRecords;
    setUpdateRecords(newState);
  }

  return (
    <div>
      <div
        className={
          "z-10 flex h-full w-full flex-col  rounded-[20px] bg-white bg-clip-border p-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto"
        }
      >
        <div className="relative flex items-center ">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {"Exam Configuration"}
          </div>
        </div>
        <div className="min-w-screen flex items-center justify-center rounded-xl bg-gray-100 p-1">
          <form
            onSubmit={formik.handleSubmit}
            className="w-full flex-col items-center space-y-5 rounded-xl bg-white p-8 shadow-lg"
          >
            {/* Text Input */}
            <div>
              <label
                htmlFor="examDurationDomain"
                className="block text-sm font-medium text-gray-700"
              >
                Exam Duration Domain
              </label>
              <input
                id="examDurationDomain"
                name="examDurationDomain"
                type="number"
                min={1}
                placeholder="Enter exam duration"
                className={`mt-1 border p-2 ${
                  formik.errors.examDurationDomain &&
                  formik.touched.examDurationDomain
                    ? "border-red-500"
                    : ""
                } rounded-md focus:outline-none focus:ring focus:ring-blue-200`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.examDurationDomain}
              />
              {formik.errors.examDurationDomain &&
              formik.touched.examDurationDomain ? (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.examDurationDomain}
                </div>
              ) : null}
            </div>

            {/* Text Input */}
            <div>
              <label
                htmlFor="examNumberOfQuestionsDomain"
                className="block text-sm font-medium text-gray-700"
              >
                Number of questions
              </label>
              <input
                id="examNumberOfQuestionsDomain"
                name="examNumberOfQuestionsDomain"
                type="number"
                min={1}
                placeholder="Enter number of questions"
                className={`mt-1 border p-2 ${
                  formik.errors.examNumberOfQuestionsDomain &&
                  formik.touched.examNumberOfQuestionsDomain
                    ? "border-red-500"
                    : ""
                } rounded-md focus:outline-none focus:ring focus:ring-blue-200`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.examNumberOfQuestionsDomain}
              />
              {formik.errors.examNumberOfQuestionsDomain &&
              formik.touched.examNumberOfQuestionsDomain ? (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.examNumberOfQuestionsDomain}
                </div>
              ) : null}
            </div>
            {/* Text Input */}
            <div>
              <label
                htmlFor="examDurationCoding"
                className="block text-sm font-medium text-gray-700"
              >
                Exam Duration Coding Exam
              </label>
              <input
                id="examDurationCoding"
                name="examDurationCoding"
                type="number"
                min={1}
                placeholder="Enter exam duration"
                className={`mt-1 border p-2 ${
                  formik.errors.examDurationCoding &&
                  formik.touched.examDurationCoding
                    ? "border-red-500"
                    : ""
                } rounded-md focus:outline-none focus:ring focus:ring-blue-200`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.examDurationCoding}
              />
              {formik.errors.examDurationCoding &&
              formik.touched.examDurationCoding ? (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.examDurationCoding}
                </div>
              ) : null}
            </div>

            {/* Text Input */}
            <div>
              <label
                htmlFor="CodingExamEasyQuestions"
                className="block text-sm font-medium text-gray-700"
              >
                Coding Exam Easy Questions
              </label>
              <input
                id="CodingExamEasyQuestions"
                name="CodingExamEasyQuestions"
                type="number"
                min={1}
                placeholder="Enter number of questions"
                className={`mt-1 border p-2 ${
                  formik.errors.CodingExamEasyQuestions &&
                  formik.touched.CodingExamEasyQuestions
                    ? "border-red-500"
                    : ""
                } rounded-md focus:outline-none focus:ring focus:ring-blue-200`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.CodingExamEasyQuestions}
              />
              {formik.errors.CodingExamEasyQuestions &&
              formik.touched.CodingExamEasyQuestions ? (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.CodingExamEasyQuestions}
                </div>
              ) : null}
            </div>
            {/* Text Input */}
            <div>
              <label
                htmlFor="CodingExamMediumQuestions"
                className="block text-sm font-medium text-gray-700"
              >
                Coding Exam Medium Questions
              </label>
              <input
                id="CodingExamMediumQuestions"
                name="CodingExamMediumQuestions"
                type="number"
                min={1}
                placeholder="Enter number of questions"
                className={`mt-1 border p-2 ${
                  formik.errors.CodingExamMediumQuestions &&
                  formik.touched.CodingExamMediumQuestions
                    ? "border-red-500"
                    : ""
                } rounded-md focus:outline-none focus:ring focus:ring-blue-200`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.CodingExamMediumQuestions}
              />
              {formik.errors.CodingExamMediumQuestions &&
              formik.touched.CodingExamMediumQuestions ? (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.CodingExamMediumQuestions}
                </div>
              ) : null}
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className={`mt-5 rounded-md bg-blue-500 py-2 px-4 text-white transition duration-200 hover:bg-blue-600 ${
                  formik.errors.examDurationDomain ||
                  formik.errors.examNumberOfQuestionsDomain ||
                  formik.errors.examDurationCoding ||
                  formik.errors.CodingExamEasyQuestions ||
                  formik.errors.CodingExamMediumQuestions ||
                  loading
                    ? " cursor-not-allowed opacity-60"
                    : " "
                }`}
                disabled={
                  formik.errors.examDurationDomain ||
                  formik.errors.examNumberOfQuestionsDomain ||
                  formik.errors.examDurationCoding ||
                  formik.errors.CodingExamEasyQuestions ||
                  formik.errors.CodingExamMediumQuestions ||
                  loading
                }
              >
                <div className="flex flex-row items-center justify-center gap-5">
                  <span>{loading ? "Submitting..." : " Submit"}</span>
                  <span>
                    <ThreeDots
                      height="30"
                      width="30"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={loading}
                    />
                  </span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnginerayConfiguration;
