import { login } from "../../apis/index";
import { Formik, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import { useState } from "react";
import toastService from "services/toastService";
import { identifierActions } from "store/features/identifierSlice";
import { useDispatch } from "react-redux";

export default function SignIn({ setToken }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const _validationSchema = Yup.object({
    username: Yup.string()
      .trim()
      .min(3, "Username must be at least 3 characters")
      .required("Username is required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,25}$/,
        "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number"
      )
      .required("Password is required"),
  });
  const navigate = useNavigate();
  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = _validationSchema;

  const onSubmitHandler = async (formikData) => {
    setLoading(true);
    const response = await login(formikData);
    console.log("Response status ", response);
    if (response.status === 200) {
      // setToken(response.data.token);
      navigate("/admin/video-vetting");
      toastService.congratulations("Welcome to Engineray Admin Panel");
      dispatch(identifierActions.setRole({ role: "admin" }));
      dispatch(identifierActions.setId({ id: response.data.data.id }));
    } else if (response.status === 403) {
      toastService.error("Invalid username or password");
    }

    setLoading(false);
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your username and password to sign in!
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onSubmitHandler(values)}
        >
          {({ handleSubmit, isValid }) => (
            <form onSubmit={handleSubmit} className="space-y-8">
              <div>
                <label htmlFor="username" className="sr-only">
                  Username
                </label>
                <Field
                  type="text"
                  name="username"
                  id="username"
                  autoComplete="given-username"
                  placeholder="Username"
                  className="w-full rounded-lg border px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-600"
                />
                <ErrorMessage name="username">
                  {(msg) => (
                    <div className="mt-1 text-sm text-red-500">{msg}</div>
                  )}
                </ErrorMessage>
              </div>

              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <Field
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  className="w-full rounded-lg border px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-600"
                />
                <ErrorMessage name="password">
                  {(msg) => (
                    <div className="mt-1 text-sm text-red-500">{msg}</div>
                  )}
                </ErrorMessage>
              </div>

              <button
                className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:bg-gray-900 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                disabled={!isValid || loading}
                type="submit"
              >
                <div className="flex flex-row items-center justify-center gap-5">
                  <span>{loading ? "Signing In" : " Sign In"}</span>
                  <span>
                    <ThreeDots
                      height="30"
                      width="30"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={loading}
                    />
                  </span>
                </div>
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
