/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";
import "./slider.css";
import TextField from "components/fields/TextField";
import {
  MediaCommunitySkin,
  MediaOutlet,
  MediaPlayer,
  MediaSlider,
} from "@vidstack/react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ResponsiveContainer,
  Brush,
} from "recharts";

const VettingRecordModal = ({
  vettingVideoRecord,
  handleCloseModal,
  toggleUpdateRecords,
}) => {
  let data;
  if (vettingVideoRecord.cumulativeDerivative) {
    data = vettingVideoRecord.cumulativeDerivative.cumulativeDerivative;
  } else {
    data = null;
  }

  const [data1, setData1] = useState(data);

  const [falsePositives, setFalsePositives] = useState([]);
  const [videoChapters, setVideoChapters] = useState(
    vettingVideoRecord.videoChapters
      ? vettingVideoRecord.videoChapters.chapters
      : []
  );

  useEffect(() => {
    if (
      vettingVideoRecord.mergedInterventionPointsAI &&
      vettingVideoRecord.mergedInterventionPointsAI.points.length > 0
    ) {
      const initialFalseNegatives =
        vettingVideoRecord.mergedInterventionPointsAI.points.map((point) => {
          const startTime = point.start * 1000;
          const endTime = point.end * 1000;
          const isTrue = 0;
          return [startTime, endTime, isTrue];
        });
      setFalsePositives(initialFalseNegatives);
    }
  }, [vettingVideoRecord.mergedInterventionPointsAI]);

  function formatTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  function convertTimeToSeconds(timeStr) {
    const [h, m, s] = timeStr.split(":").map(Number);
    return h * 3600 + m * 60 + s;
  }

  function calculatePercentage(startTime, endTime, totalDuration) {
    const startTimeSeconds = convertTimeToSeconds(startTime);
    const endTimeSeconds = convertTimeToSeconds(endTime);
    const totalDurationSeconds = convertTimeToSeconds(totalDuration);

    const intervalDuration = endTimeSeconds - startTimeSeconds;
    const percentage = (intervalDuration / totalDurationSeconds) * 100;

    return percentage;
  }
  function stepSizeChangeHandler(stepSize) {
    const updatedData = data.reduce((acc, curr, idx) => {
      const groupIndex = Math.floor(idx / stepSize);
      if (!acc[groupIndex]) {
        acc[groupIndex] = { frameNumber: 0, switchValue: 0 };
      }
      acc[groupIndex].switchValue += curr.switchValue;
      acc[groupIndex].frameNumber = parseInt(curr.frameNumber);
      return acc;
    }, []);

    setData1(updatedData);
  }

  return (
    <div className="modal fixed top-10 left-0 flex h-screen w-full items-center justify-center pt-20">
      <div className="modal-overlay absolute h-screen w-screen bg-gray-900 opacity-50"></div>
      <div className="modal-container z-50 max-h-full w-11/12 overflow-y-auto rounded bg-white shadow-lg md:w-10/12 lg:w-9/12">
        <div className="modal-content py-4 px-6 text-left">
          <h2
            onClick={handleCloseModal}
            className="modal-header mb-4  text-right text-2xl font-bold hover:cursor-pointer"
          >
            X
          </h2>
          <h2 className="modal-header mb-4 text-center text-3xl font-bold">
            Vetting Record (viewing mode only)
          </h2>
          <h2 className="modal-header mb-4 text-center text-3xl font-bold">
            {`${vettingVideoRecord.applicant.firstName} ${
              vettingVideoRecord.applicant.lastName
            } : ${
              vettingVideoRecord.skill
                ? vettingVideoRecord.skill.name
                : "Coding"
            } Exam`}
          </h2>
          <div className="flex flex-col items-center">
            <MediaPlayer
              title="Exam Video"
              src={`${process.env.REACT_APP_BACKEND_URL}/${vettingVideoRecord.mergedVideoUrl}`}
              crossorigin=""
              aspectRatio={16 / 9}
            >
              <MediaOutlet>
                <track
                  src={`${process.env.REACT_APP_BACKEND_URL}/${vettingVideoRecord.inteventionChapterFile}`}
                  srcLang="en-US"
                  kind="chapters"
                  default
                />
                {/* <MediaPlayButton /> */}
              </MediaOutlet>

              <MediaCommunitySkin />
              {videoChapters && (
                <div className="mt-10 ml-5 mb-2 mr-4 flex flex-row ">
                  {videoChapters.map((point, index) => {
                    const percentage = calculatePercentage(
                      point[0],
                      point[1],
                      vettingVideoRecord.videoLength
                    );
                    if (point[2].toLowerCase() === "non-cheating") {
                      return (
                        <MediaSlider
                          key={index}
                          min={0}
                          max={100}
                          value={0}
                          aria-label="..."
                          className="z-50 m-0  h-0.5"
                          style={{ width: `${percentage}%` }}
                          trackFillClass="not-cheating"
                          trackProgressClass="not-cheating"
                          disabled={true}
                        />
                      );
                    } else {
                      return (
                        <MediaSlider
                          key={index}
                          min={0}
                          max={100}
                          value={0}
                          aria-label="..."
                          className="z-50 m-0 h-0.5"
                          style={{ width: `${percentage}%` }}
                          trackFillClass="cheating"
                          trackProgressClass="cheating"
                          disabled={true}
                        />
                      );
                    }
                  })}
                </div>
              )}
            </MediaPlayer>
          </div>

          <div className="my-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              Cheating Points Manual
            </h4>

            {vettingVideoRecord.interventionPointsHuman &&
              vettingVideoRecord.interventionPointsHuman.points.length > 0 && (
                <div className="mt-10">
                  <p className="mb-5 text-center text-lg font-semibold">
                    Human detected unusual behavior in the following timestamps
                  </p>

                  <div className="todo-text-alignment">
                    {vettingVideoRecord.interventionPointsHuman.points.map(
                      (point, index) => {
                        const startTimeInSeconds = point[0]; // Convert start time to minutes
                        const endTimeInSeconds = point[1]; // Convert end time to minutes
                        return (
                          <div
                            key={`${index}_${point[0]}`}
                            className="mb-2 flex w-full items-center justify-center gap-5 text-2xl"
                          >
                            <h3 className="modal-subheader font-semibold">
                              {startTimeInSeconds} - {endTimeInSeconds}
                            </h3>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
          </div>
          <div className="my-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              Gaze Switches
            </h4>
            {data ? (
              <div className="min-h-5/6 flex  w-full items-start justify-center gap-5 bg-white text-2xl">
                <ResponsiveContainer width="90%" height={500}>
                  <LineChart
                    width={700}
                    height={500}
                    data={data1}
                    margin={{
                      top: 50,
                      right: 100,
                      left: 100,
                      bottom: 50,
                    }}
                  >
                    <Line
                      type="monotone"
                      dataKey="switchValue"
                      stroke="#8884d8"
                      strokeWidth={3}
                      dot={false}
                    />
                    <Brush
                      onChange={function noRefCheck() {}}
                      gap={60}
                      endIndex={60}
                    />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="frameNumber">
                      <Label
                        value={"Frame Number "}
                        offset={60}
                        position={"bottom"}
                      />
                    </XAxis>
                    <YAxis
                      dataKey="switchValue"
                      label={{
                        value: "Rate of Switches",
                        angle: -90,
                        position: "left",
                        offset: 50,
                      }}
                    />

                    <Tooltip />
                  </LineChart>
                </ResponsiveContainer>
                <div className="w-20">
                  <label
                    for="countries"
                    class="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-400"
                  >
                    Choose step size
                  </label>
                  <select
                    defaultValue="10"
                    onChange={(e) =>
                      stepSizeChangeHandler(parseInt(e.target.value))
                    }
                    id="countries"
                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </div>
            ) : (
              " "
            )}
          </div>
          <div className="my-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              AI Confidence Score
            </h4>
            <div className="flex w-full items-center justify-center gap-5 text-2xl">
              {vettingVideoRecord.aiConfidenceScore === null ? (
                <h3 className="modal-subheader ">Not Available</h3>
              ) : (
                <h3 className="modal-subheader font-semibold">
                  {`${vettingVideoRecord.aiConfidenceScore}/5`}
                </h3>
              )}
            </div>
          </div>

          <div className="my-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              Identity Verification
            </h4>
            <div className="flex w-full items-center justify-center gap-5 text-2xl">
              <h3 className="modal-subheader font-semibold">
                AI Result:{" "}
                <>
                  {vettingVideoRecord.idCheck === true && (
                    <span className="text-green-900"> Passed </span>
                  )}
                  {vettingVideoRecord.idCheck === false && (
                    <span className="text-red-900"> Failed </span>
                  )}
                  {vettingVideoRecord.idCheck === null && (
                    <span className="text-gray-900"> Not Vetted </span>
                  )}
                </>
              </h3>

              <h3 className="modal-subheader font-semibold">Human Result:</h3>

              <>
                <>
                  {vettingVideoRecord.idCheckHuman === true && (
                    <span className="font-semibold text-green-900">Passed</span>
                  )}
                  {vettingVideoRecord.idCheckHuman === false && (
                    <span className="font-semibold text-red-900"> Failed </span>
                  )}
                  {vettingVideoRecord.idCheckHuman === null && (
                    <span className="font-semibold text-gray-900">
                      Not Vetted
                    </span>
                  )}
                </>
              </>
            </div>
          </div>

          <div className="mt-10 rounded-md bg-gray-200 p-5">
            <h4 className=" mb-5 w-full text-center text-3xl font-bold">
              Room Tour
            </h4>
            <div className="flex w-full items-center justify-center gap-5 text-2xl">
              <h3 className="modal-subheader font-semibold">
                AI Result:{" "}
                <>
                  {vettingVideoRecord.roomTour === true && (
                    <span className="text-green-900"> Passed </span>
                  )}
                  {vettingVideoRecord.roomTour === false && (
                    <span className="text-red-900"> Failed </span>
                  )}
                  {vettingVideoRecord.roomTour === null && (
                    <span className="text-gray-900"> Not Vetted </span>
                  )}
                </>
              </h3>

              <h3 className="modal-subheader font-semibold">Human Result:</h3>
              <>
                {vettingVideoRecord.roomTourHuman === true && (
                  <span className="font-semibold text-green-900">Passed</span>
                )}
                {vettingVideoRecord.roomTourHuman === false && (
                  <span className="font-semibold text-red-900"> Failed </span>
                )}
                {vettingVideoRecord.roomTourHuman === null && (
                  <span className="font-semibold text-gray-900">
                    Not Vetted
                  </span>
                )}
              </>
            </div>
          </div>

          <div className="mt-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              Gaze Detection{" "}
            </h4>
            <p className="mb-5 text-center text-lg font-semibold">
              AI detected unusual behavior in the following timestamps
            </p>
            <div className="todo-text-alignment">
              {vettingVideoRecord.mergedInterventionPointsAI &&
                vettingVideoRecord.mergedInterventionPointsAI.points.map(
                  (point, index) => {
                    const startTimeInSeconds = formatTime(point.start * 1000); // Convert start time to minutes
                    const endTimeInSeconds = formatTime(point.end * 1000); // Convert end time to minutes
                    return (
                      <div
                        key={index}
                        className="my-2 flex w-full items-center justify-center gap-5 text-2xl"
                      >
                        <h3 className="modal-subheader font-semibold">
                          {startTimeInSeconds} - {endTimeInSeconds}
                        </h3>
                      </div>
                    );
                  }
                )}
            </div>
          </div>

          <div className="mt-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              Overall Result
            </h4>
            <div className="flex w-full items-center justify-center gap-5 text-2xl">
              <>
                {vettingVideoRecord.humanStatus === "Pass" && (
                  <span className="font-semibold text-green-900">Passed</span>
                )}
                {vettingVideoRecord.humanStatus === "Fail" && (
                  <span className="font-semibold text-red-900"> Failed </span>
                )}
                {vettingVideoRecord.humanStatus === "not vetted" && (
                  <span className="font-semibold text-gray-900">
                    Not Vetted
                  </span>
                )}
              </>
            </div>
          </div>

          <div className="mt-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              Vetting Comments
            </h4>

            <div className="flex w-full items-center justify-center gap-5 text-2xl">
              <TextField
                label="Comments"
                className="cursor-default resize-none p-2 text-sm"
                placeholder="Share your observations while vetting."
                id="textarea"
                cols="50"
                rows="2"
                name="vettingComments"
                readOnly={true}
                value={
                  vettingVideoRecord.vettingComments === null
                    ? " "
                    : vettingVideoRecord.vettingComments
                }
              />
            </div>
          </div>
          <div className="mt-5 flex items-center justify-center gap-5 pb-10">
            <button
              className="modal-close rounded bg-red-500 py-2 px-4 font-bold text-white hover:bg-red-700"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VettingRecordModal;
