import React from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
// import MyModal from "components/modal/ModalCustom";

import { useSelector } from "react-redux";
import usePersistentSession from "components/customHooks/usePersistentSession";
import LoaderSpinner from "components/loader/LoaderSpinner";
import {
  setupAxiosInterceptors,
  setupAxiosInterceptorsUploadApi,
  setupAxiosInterceptorsUniversityApi,
} from "apis";

const App = () => {
  const navigate = useNavigate();

  const { loading } = usePersistentSession();
  const role = useSelector((state) => state.identifier.role);
  const id = useSelector((state) => state.identifier.id);
  React.useEffect(() => {
    const handleAuthError = () => {
      navigate("/"); // Redirect to the login page
    };
    setupAxiosInterceptors(handleAuthError);
    setupAxiosInterceptorsUploadApi(handleAuthError);
    setupAxiosInterceptorsUniversityApi(handleAuthError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LoaderSpinner isLoading={loading} />;
  } else {
    if (!role || !id) {
      console.log("Here");

      return (
        // <AuthLayout setToken={setToken} />
        <Routes>
          <Route path="/" element={<AuthLayout />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/" element={<AuthLayout />} />
          <Route path="/admin/*" element={<AdminLayout />} />
          <Route path="*" element={<Navigate to="/admin/" replace />} />
          {/* <Route path="modal/custom" element={<MyModal />} /> */}
        </Routes>
      );
    }
  }
};

export default App;
