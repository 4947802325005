import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  withCredentials: true,
  headers: {
    "content-type": "application/json",
  },
});

const universityApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL_UNIVERSITY,
  withCredentials: true,
  headers: {
    "content-type": "application/json",
  },
});

const uploadApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  withCredentials: true,

  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const login = async (data) => {
  try {
    const response = await api
      .post("/admin/login/", data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });

    return response;
  } catch (error) {
    return error;
  }
};

export const getAllVettingVideos = async () => {
  try {
    const response = await api.get(`/exam-vetting/records/`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getAllVettingVideosUniversity = async () => {
  try {
    const response = await universityApi.get(`/exam-vetting/records/`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getPlansAndLimits = async () => {
  try {
    const response = await api.get(`/resource-usage/plans`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getFeatureFlags = async (data) => {
  try {
    const response = await api
      .get("/feature-flags/all", data)
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updatePlanUsage = async (data) => {
  try {
    const response = await api
      .post("/resource-usage/update/plan-usage", data)
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateFeatureFlags = async (data) => {
  try {
    const payload = {
      featureUpdate: data,
    };
    const response = await api
      .post("/update/feature-flags", payload)
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteExam = async (data) => {
  try {
    const response = await api
      .post("/delete-exam", data)
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllVettingVideosUniversityError = async () => {
  try {
    const response = await universityApi.get(
      `/exam-vetting/records/gaze-error`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getSupportTicketUniversity = async () => {
  try {
    const response = await api.get(`/support/all/applicant/tickets`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getExamFeedbackApplicants = async () => {
  try {
    const response = await api.get(`/exam-feedback/all/applicant/feedbacks`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDemoRequests = async () => {
  try {
    const response = await api.get(`/request-demo/requests/`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getVettingRecordUniversity = async (recordId) => {
  const payload = JSON.stringify({
    recordId: recordId,
  });
  try {
    const response = await universityApi.post(
      `/exam-vetting/get/record/admin/`,
      payload
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getVettingRecordUniversityUpdate = async (recordId) => {
  const payload = JSON.stringify({
    recordId: recordId,
  });
  try {
    const response = await universityApi.post(
      `/exam-vetting/get/record/update`,
      payload
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getVettingRecord = async (recordId) => {
  const payload = JSON.stringify({
    recordId: recordId,
  });
  try {
    const response = await api.post(`/exam-vetting/get/record/`, payload);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getExamFeedback = async (recordId) => {
  const payload = JSON.stringify({
    recordId: recordId,
  });
  try {
    const response = await api.post(
      `/exam-feedback/applicant/${recordId}/feedback`,
      payload
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getEnginerayConfiguration = async () => {
  try {
    const response = await api.get(`/engineray-configuration/`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateEnginerayExamConfiguration = async (data) => {
  try {
    const response = await api
      .put("/engineray-configuration/update", data)
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateDemoRequestStatus = async (data) => {
  try {
    const response = await api
      .put("/request-demo/update/request-status", data)
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

// TODO
// [ ]: think about how to secure this api ? since it is used by company, admin dashboard and question generator....
export const addNewSkillWithQuestions = async (data) => {
  try {
    const response = await uploadApi
      .post("/questionPool", data)
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};
export const addNewSkillWithQuestionsOpenEnded = async (data) => {
  try {
    const response = await uploadApi
      .post("/questionPoolOpenEnded", data)
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateExistingSkillEngineray = async (data) => {
  try {
    const response = await uploadApi
      .post("/skills/update", data)
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteEnginerayCodingQuestion = async (data) => {
  try {
    const response = await api
      .post("/questions/delete/engineray/codingQuestion", data)
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const addNewSkillTopicGenerator = async (data) => {
  try {
    const response = await api
      .post("/question-generator/engineray/generate-questions-topic", data)
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};
export const getNotificationsCompany = async (companyId) => {
  try {
    const response = await api
      .get("/notifications/company/" + companyId)
      .then((res) => {
        //     console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const markAsReadNotificationsCompany = async (companyId) => {
  try {
    const response = await api
      .get("/notifications/read/company/" + companyId)
      .then((res) => {
        //       console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};
export const logout = async () => api.post("/logout");

// TODO
// [ ]: think about how to secure this api ? since it is used by company, admin dashboard and question generator....
export const addQuestionsSkills = async (skillId, file) => {
  try {
    const payload = {
      csvData: file,
    };
    const response = await uploadApi
      .put(`/questionPool/${skillId}`, payload)
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

// TODO
// [ ]: think about how to secure this api ? since it is used by company, admin dashboard and question generator....
export const addQuestionsOpenEndedSkills = async (skillId, file) => {
  try {
    const payload = {
      csvData: file,
    };
    const response = await uploadApi
      .put(`/questionPoolOpenEnded/${skillId}`, payload)
      .then((res) => {
        console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllSkills = async () => {
  try {
    const response = await api
      .get(`/skills/skills-all`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getAllSkillsAndQuestionCount = async () => {
  try {
    const response = await api
      .get(`/skills/dashboard/all`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const createCodingProblemEngineray = async (payload) => {
  try {
    const response = await api
      .post(`/questions/create/codingProblem/engineray`, payload)
      .then((res) => {
        //          console.log(res.status);
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllCodingProblems = async () => {
  try {
    const response = await api
      .get(`/questions/coding-questions/dashboard`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getAllQuestions = async () => {
  try {
    const response = await api
      .get(`/questionPool/all`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
      });

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateExamVettingStatusHuman = async (
  recordId,
  humanStatus,
  humanComments,
  idCheckHuman,
  roomTourHuman,
  interventionPointsHuman,
  falsePositives
) => {
  const payload = JSON.stringify({
    recordId: recordId,
    humanVettingResult: humanStatus,
    humanVettingComments: humanComments,
    idCheckHuman: idCheckHuman,
    roomTourHuman: roomTourHuman,
    interventionPointsHuman: interventionPointsHuman,
    falsePositives: falsePositives,
  });
  try {
    const response = await api.post(
      `/exam-vetting/update-human/record`,
      payload
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateExamVettingStatusHumanUniversity = async (
  recordId,
  humanStatus,
  humanComments,
  idCheckHuman,
  roomTourHuman,
  interventionPointsHuman,
  falsePositives
) => {
  const payload = JSON.stringify({
    recordId: recordId,
    humanVettingResult: humanStatus,
    humanVettingComments: humanComments,
    idCheckHuman: idCheckHuman,
    roomTourHuman: roomTourHuman,
    interventionPointsHuman: interventionPointsHuman,
    falsePositives: falsePositives,
  });
  try {
    const response = await universityApi.post(
      `/exam-vetting/update-human/record`,
      payload
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// // ?interceptor for auto token refresh
// api.interceptors.response.use(
//   (config) => {
//     return config;
//   },
//   // eslint-disable-next-line consistent-return
//   async (error) => {
//     const originalRequest = error.config;
//     console.log(
//       "Refresh url : ",
//       process.env.REACT_APP_BACKEND_API_REFRESH_URL
//     );
//     if (
//       error.response &&
//       error.response.status === 401 &&
//       originalRequest &&
//       !originalRequest._isRetry
//     ) {
//       originalRequest._isRetry = true;

//       try {
//         await axios.get(process.env.REACT_APP_BACKEND_API_REFRESH_URL, {
//           withCredentials: true,
//         });

//         return api.request(originalRequest);
//       } catch (err) {
//         return err;
//       }
//     }

//     throw error;
//   }
// );

export const setupAxiosInterceptors = (onAuthError) => {
  console.log("API INTERCEPTOR-----");
  const responseInterceptor = api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        originalRequest &&
        !originalRequest._isRetry
      ) {
        originalRequest._isRetry = true;
        try {
          await axios.get(process.env.REACT_APP_BACKEND_API_REFRESH_URL, {
            withCredentials: true,
          });

          return axios(originalRequest);
        } catch (err) {
          if (onAuthError) {
            onAuthError(); // Invoke the callback on retry failure
          }
          return Promise.reject(err);
        }
      }

      return Promise.reject(error);
    }
  );

  return () => {
    api.interceptors.response.eject(responseInterceptor);
  };
};

export const setupAxiosInterceptorsUploadApi = (onAuthError) => {
  const responseInterceptor = uploadApi.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        originalRequest &&
        !originalRequest._isRetry
      ) {
        originalRequest._isRetry = true;
        try {
          await axios.get(process.env.REACT_APP_BACKEND_API_REFRESH_URL, {
            withCredentials: true,
          });

          return axios(originalRequest);
        } catch (err) {
          if (onAuthError) {
            onAuthError(); // Invoke the callback on retry failure
          }
          return Promise.reject(err);
        }
      }

      return Promise.reject(error);
    }
  );

  return () => {
    uploadApi.interceptors.response.eject(responseInterceptor);
  };
};

export const setupAxiosInterceptorsUniversityApi = (onAuthError) => {
  const responseInterceptor = universityApi.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        originalRequest &&
        !originalRequest._isRetry
      ) {
        originalRequest._isRetry = true;
        try {
          await axios.get(process.env.REACT_APP_BACKEND_API_REFRESH_URL, {
            withCredentials: true,
          });

          return axios(originalRequest);
        } catch (err) {
          if (onAuthError) {
            onAuthError(); // Invoke the callback on retry failure
          }
          return Promise.reject(err);
        }
      }

      return Promise.reject(error);
    }
  );

  return () => {
    universityApi.interceptors.response.eject(responseInterceptor);
  };
};

// uploadApi.interceptors.response.use(
//   (config) => {
//     return config;
//   },
//   // eslint-disable-next-line consistent-return
//   async (error) => {
//     const originalRequest = error.config;
//     console.log(
//       "Refresh url : ",
//       process.env.REACT_APP_BACKEND_API_REFRESH_URL
//     );
//     if (
//       error.response &&
//       error.response.status === 401 &&
//       originalRequest &&
//       !originalRequest._isRetry
//     ) {
//       originalRequest._isRetry = true;

//       try {
//         await axios.get(process.env.REACT_APP_BACKEND_API_REFRESH_URL, {
//           withCredentials: true,
//         });

//         return uploadApi.request(originalRequest);
//       } catch (err) {
//         return err;
//       }
//     }

//     throw error;
//   }
// );

// universityApi.interceptors.response.use(
//   (config) => {
//     return config;
//   },
//   // eslint-disable-next-line consistent-return
//   async (error) => {
//     const originalRequest = error.config;
//     console.log(
//       "Refresh url : ",
//       process.env.REACT_APP_BACKEND_API_REFRESH_URL
//     );
//     if (
//       error.response &&
//       error.response.status === 401 &&
//       originalRequest &&
//       !originalRequest._isRetry
//     ) {
//       originalRequest._isRetry = true;

//       try {
//         await axios.get(process.env.REACT_APP_BACKEND_API_REFRESH_URL, {
//           withCredentials: true,
//         });

//         return universityApi.request(originalRequest);
//       } catch (err) {
//         return err;
//       }
//     }

//     throw error;
//   }
// );
