/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { demoRequestColumns } from "./variables/columnData";
import DemoRequestTable from "./components/demoRequestTable";
import { getDemoRequests } from "../../../apis";
import DemoRequestModal from "./components/DemoRequestModal";
import toastService from "../../../services/toastService";

const SupportTickets = () => {
  const [demoRequests, setDemoRequests] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [demoRequestUpdateStatus, setDemoRequestUpdateStatus] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await getDemoRequests();

        if (response.status === 200) {
          setDemoRequests(response.data.requests);
        } else {
          toastService.error("Unable to get demo requests");
        }
      } catch (error) {
        console.log("error ");
        toastService.error("Something went wrong");
      }
    })();
  }, [refresh]);

  // Handle clicking the update status button
  const onUpdateStatusClickHandler = async (requestId) => {
    // Find the selected record by its request ID
    const selectedRecord = demoRequests.find(
      (request) => request.id === requestId
    );

    // If the record is found, set it in the state and open the modal
    if (selectedRecord) {
      setDemoRequestUpdateStatus(selectedRecord); // Set the selected record
      setShowModal(true); // Show the modal
    } else {
      console.log("Record not found");
    }
  };

  // Handle closing the modal
  const closeModal = () => {
    setShowModal(false); // Close the modal
    setDemoRequestUpdateStatus(null); // Clear the selected record
  };
  return (
    <div>
      <DemoRequestTable
        columnsData={demoRequestColumns}
        tableData={demoRequests}
        tableTittle={"Demo Requests"}
        onUpdateStatusClickHandler={onUpdateStatusClickHandler}
      />
      {/* Modal for updating the status of a demo request */}
      {showModal && demoRequestUpdateStatus && (
        <DemoRequestModal
          demoRequest={demoRequestUpdateStatus}
          onClose={closeModal}
          setRefresh={setRefresh}
        />
      )}
    </div>
  );
};

export default SupportTickets;
