import Footer from "components/footer/FooterAuthDefault";
import logo from "assets/img/auth/logo.png";
// import { Routes, Route, Navigate } from "react-router-dom";
// import routes from "routes.js";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import SignIn from "../../views/auth/SignIn";
export default function Auth({ setToken }) {
  // const getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.layout === "/auth") {
  //       return (
  //         <Route path={`/${prop.path}`} element={prop.component} key={key} />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                {/* <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                </Routes> */}
                <SignIn setToken={setToken} />
                <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
                  <div className="absolute flex h-full w-full flex-col items-center justify-center gap-5 bg-gradient-to-r from-indigo-500 via-indigo-600 to-indigo-800 lg:rounded-bl-[120px] xl:rounded-bl-[200px]">
                    <div className="flex items-center">
                      <img className=" h-15 w-15" src={logo} alt="logo" />
                      <h1 className=" ml-4 text-4xl font-bold text-white">
                        EngineRay
                      </h1>
                    </div>

                    <h1 className="text-2xl font-semibold text-white">
                      Admin Dashboard
                    </h1>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
