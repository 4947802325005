/* eslint-disable no-unused-vars */

import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useGroupBy,
  useExpanded,
} from "react-table";
import {
  MdCheckCircle,
  MdCancel,
  MdOutlineError,
  MdVideoLibrary,
  MdVideoLabel,
  MdLensBlur,
  MdImage,
  MdFeed,
} from "react-icons/md";
import { useMemo } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import TableCellContent from "./TableCellComponent";

const ExamFeedbackTable = (props) => {
  const { columnsData, tableData, tableTittle, handleViewFeedback } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  // const data =  useMemo(() => tableData, [tableData]);
  const data = tableData;

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },

    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setGroupBy,
    state: { pageIndex, pageSize, groupBy, expanded },
    setPageSize,
  } = tableInstance;

  return (
    <div
      className={
        "z-10 flex h-full w-full flex-col  rounded-[20px] bg-white bg-clip-border p-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto"
      }
    >
      <div className="mt-8 h-full overflow-x-auto " style={{ height: "650px" }}>
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps()}
                    key={index}
                    className="text-black border-b border-gray-200 border-navy-700 pr-28 pb-[10px] text-start"
                  >
                    <div className="flex flex-row">
                      {column.canGroupBy && column.Header === "APPLICANT" ? (
                        // If the column can be grouped, let's add a toggle
                        <span
                          className="mr-3"
                          {...column.getGroupByToggleProps()}
                        >
                          {column.isGrouped ? "➡️" : "⬇️  "}
                        </span>
                      ) : null}
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index} className="py-4">
                  {row.cells.map((cell, index) => {
                    if (cell.isGrouped) {
                      return (
                        <>
                          <p className="text-black py-3 text-sm font-bold text-navy-700">
                            <span {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? "⬇️" : "➡️"}
                            </span>{" "}
                            {cell.render("Cell")} ({row.subRows.length})
                          </p>
                        </>
                      );
                    } else if (cell.isAggregated) {
                      return null;
                    } else if (cell.isPlaceholder) {
                      return <span className="w-5"></span>;
                    } else {
                      let data = "";
                      if (cell.column.id === "applicant") {
                        data = (
                          <p className="text-black text-sm font-bold text-navy-700">
                            {cell.value}
                          </p>
                        );
                      } else if (cell.column.id === "examType") {
                        data = (
                          <p className="text-black text-sm font-bold text-navy-700">
                            {cell.value}
                          </p>
                        );
                      } else if (cell.column.id === "skill") {
                        data = (
                          <p className="text-black text-sm font-bold text-navy-700">
                            {cell.value}
                          </p>
                        );
                      } else if (cell.column.id === "overallComments") {
                        data = (
                          <p className="text-black text-sm font-bold text-navy-700">
                            <TableCellContent
                              text={cell.value}
                              maxLength={50}
                            />
                          </p>
                        );
                      } else if (cell.column.id === "overallRating") {
                        data = (
                          <p className="text-black text-sm font-bold text-navy-700">
                            {cell.value}/5
                          </p>
                        );
                      } else if (cell.column.id === "createdAt") {
                        data = (
                          <p className="text-black text-sm font-bold text-navy-700">
                            {cell.value}
                          </p>
                        );
                      } else if (cell.column.id === "action") {
                        data = (
                          <div className={`rounded-full text-xl`}>
                            {cell.value && cell.value.view && (
                              <MdFeed
                                data-tooltip-id="view-feedback"
                                data-tooltip-content="Click to view exam feedback"
                                onClick={() => {
                                  handleViewFeedback(
                                    cell.value.view.feedbackId
                                  );
                                }}
                                className="text-gray-800-500 hover:cursor-pointer"
                              />
                            )}
                            <Tooltip id="view-feedback" />
                          </div>
                        );
                      }
                      return (
                        <td
                          className="pt-[14px] pb-[18px] sm:text-[14px]"
                          {...cell.getCellProps()}
                          key={index}
                        >
                          {data}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex items-center justify-between border-t-2 border-gray-600">
        <div className="mt-3">
          <span className="text-s text-black mr-3">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>

          <span className="text-s text-black mr-3">
            Go to page:{" "}
            <input
              type="number"
              min={1}
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              className="border-black text-black mx-2 w-20 rounded border p-1 text-center focus:outline-none focus:ring-2  focus:ring-blue-600"
            />
          </span>

          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="text-black rounded border p-1 text-xs focus:outline-none focus:ring-2  focus:ring-blue-600"
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>

        <div>
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="mx-1 rounded bg-blue-500 px-3 py-1 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {"<<"}
          </button>

          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="mx-1 rounded bg-blue-500 px-3 py-1 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {"<"}
          </button>

          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="mx-1 rounded bg-blue-500 px-3 py-1 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {">"}
          </button>

          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="mx-1 rounded bg-blue-500 px-3 py-1 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {">>"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamFeedbackTable;
