import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import toastService from "services/toastService";
import { getFeatureFlags, updateFeatureFlags } from "../../../apis";

const FeatureFlags = () => {
  const [loading, setLoading] = useState(false);
  const [featureFlags, setFeatureFlags] = useState([]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      const featureUpdate = Object.keys(values).map((key) => ({
        featureId: parseInt(key), // Send the feature ID for the update
        isEnabled: values[key] === "enabled", // Convert to boolean based on selection
      }));

      console.log("Feature Update : ", featureUpdate);
      try {
        setLoading(true);
        const response = await updateFeatureFlags(featureUpdate);

        if (response.status === 200) {
          toastService.success("Feature flags updated successfully");
        } else {
          toastService.error("Something went wrong while updating");
        }
      } catch (error) {
        console.log("Error:", error);
        toastService.error("An error occurred while updating feature flags");
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      try {
        const response = await getFeatureFlags();
        if (response.status === 200) {
          const fetchedFlags = response.data.flags;

          // Populate formik initial values with current feature flag status
          const initialValues = {};
          fetchedFlags.forEach((flag) => {
            initialValues[flag.featureId] = flag.isEnabled
              ? "enabled"
              : "disabled";
          });
          formik.setValues(initialValues);
          setFeatureFlags(fetchedFlags);
        } else {
          toastService.error("Unable to fetch feature flags");
        }
      } catch (error) {
        console.log("Error fetching feature flags:", error);
      }
    };

    fetchFeatureFlags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div
        className={
          "z-10 flex h-full w-full flex-col  rounded-[20px] bg-white bg-clip-border p-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto"
        }
      >
        <div className="relative flex items-center ">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {"Feature Flag Configuration"}
          </div>
        </div>

        <div className="min-w-screen flex items-center justify-center rounded-xl bg-gray-100 p-1">
          <form
            onSubmit={formik.handleSubmit}
            className="w-full flex-col items-center space-y-5 rounded-xl bg-white p-8 shadow-lg"
          >
            {featureFlags.map((flag) => (
              <div key={flag.featureId}>
                <label
                  htmlFor={flag.featureId}
                  className="block text-sm font-medium text-gray-700"
                >
                  {flag.flagName}
                </label>
                <select
                  id={flag.featureId}
                  name={flag.featureId}
                  className="mt-1 block w-full rounded-md border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[flag.featureId] || ""}
                >
                  <option value="enabled">Enabled</option>
                  <option value="disabled">Disabled</option>
                </select>
              </div>
            ))}

            <div>
              <button
                type="submit"
                className={`mt-5 rounded-md bg-blue-500 py-2 px-4 text-white transition duration-200 hover:bg-blue-600 ${
                  loading ? "cursor-not-allowed opacity-60" : ""
                }`}
                disabled={loading}
              >
                <div className="flex flex-row items-center justify-center gap-5">
                  <span>{loading ? "Submitting..." : "Submit"}</span>
                  <span>
                    <ThreeDots
                      height="30"
                      width="30"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={loading}
                    />
                  </span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FeatureFlags;
