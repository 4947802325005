import { useEffect, useRef, useState } from "react";

import { useFormik } from "formik";

import {
  addQuestionsOpenEndedSkills,
  addQuestionsSkills,
  getAllQuestions,
  getAllSkills,
} from "apis";
import { ThreeDots } from "react-loader-spinner";
import toastService from "services/toastService";
import QuestionTable from "./components/QuestionTable";
import { questionTableColumns } from "./variable/columnsData";
const Questions = () => {
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [questionRecords, setQuestionRecords] = useState([]);

  const [skills, setSkills] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [updateRecords, setUpdateRecords] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllSkills();
        setSkills(response.data.skills);
      } catch (error) {
        console.log("error ");
      }
    })();

    (async () => {
      try {
        const response = await getAllQuestions();
        setQuestionRecords(response.data.questions);
      } catch (error) {
        console.log("error ");
      }
    })();
  }, [updateRecords]);

  const clearFile = () => {
    formik.setFieldValue("csvFile", null);

    // Use the ref to clear the file input's display value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.skillName) {
      errors.skillName = "Required";
    }
    if (!values.csvFile) {
      errors.csvFile = "File is required";
    }
    return errors;
  };

  const mapSkillType = (skillType) => {
    if (skillType === "MCQ") {
      return "MCQ";
    } else if (skillType === "OpenEnded") {
      return "Open Ended";
    }
  };

  const formik = useFormik({
    initialValues: {
      skillName: "",
      csvFile: null,
    },
    validate,
    onSubmit: async (values) => {
      console.log("Form values:", values);

      // Here you can handle the file data further, e.g., uploading to a server
      const file = values.csvFile;

      const skillId = parseInt(values.skillName);

      const skillSelected = skills.find((skill) => skill.id === skillId);

      console.log("Selected Skill :", skillSelected);

      if (file) {
        setLoading(true);
        console.log(`Uploaded file name: ${file.name}`);

        let response = null;
        if (skillSelected.questionType === "MCQ") {
          response = await addQuestionsSkills(skillId, file);
        } else {
          response = await addQuestionsOpenEndedSkills(skillId, file);
        }
        if (response.status === 201) {
          toastService.success("Questions Added");
        } else if (response.status === 404) {
          toastService.warning("Skill Not found");
        } else {
          toastService.error(response.data.message);
        }
      } else {
        toastService.error("Please select a file");
      }

      setLoading(false);
      formik.resetForm();
      clearFile();
    },
  });
  return (
    <div>
      <div
        className={
          "z-10 flex h-full w-full flex-col  rounded-[20px] bg-white bg-clip-border p-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto"
        }
      >
        <div className="relative flex items-center ">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {"Add Questions"}
          </div>
        </div>
        <div className="min-w-screen flex items-center justify-center rounded-xl bg-gray-100 p-1">
          <form
            onSubmit={formik.handleSubmit}
            className="flex w-full items-center space-x-6 rounded-xl bg-white p-8 shadow-lg"
          >
            {/* Select Dropdown for Skills */}
            <div>
              <label
                htmlFor="skillName"
                className="block text-sm font-medium text-gray-700"
              >
                Skill Name
              </label>
              <select
                id="skillName"
                name="skillName"
                className={`mt-1 border p-2 ${
                  formik.errors.skillName && formik.touched.skillName
                    ? "border-red-500"
                    : ""
                } rounded-md focus:outline-none focus:ring focus:ring-blue-200`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.skillName}
              >
                <option value="" label="Select a skill" />
                {skills.map((skill) => (
                  <option
                    key={skill.id}
                    value={skill.id}
                    label={`${skill.name} - ${mapSkillType(
                      skill.questionType
                    )}`}
                  />
                ))}
              </select>
              {formik.errors.skillName && formik.touched.skillName ? (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.skillName}
                </div>
              ) : null}
            </div>

            {/* File Input */}
            <div>
              <label
                htmlFor="csvFile"
                className="block text-sm font-medium text-gray-700"
              >
                Questions CSV File
              </label>
              <input
                ref={fileInputRef}
                id="csvFile"
                name="csvFile"
                type="file"
                accept=".csv"
                className={`mt-1 rounded-md border p-2 ${
                  formik.errors.csvFile && formik.touched.csvFile
                    ? "border-red-500"
                    : ""
                } focus:outline-none focus:ring focus:ring-blue-200`}
                onChange={(event) => {
                  formik.setFieldValue("csvFile", event.currentTarget.files[0]);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.errors.csvFile && formik.touched.csvFile ? (
                <div className="mt-1 text-sm text-red-500">
                  {formik.errors.csvFile}
                </div>
              ) : null}
            </div>

            {/* Submit Button */}
            <div className="">
              <button
                type="button"
                className="mx-4 rounded-md bg-red-500 py-1 px-2 text-white transition duration-200 hover:bg-red-600"
                onClick={clearFile}
              >
                Clear File
              </button>
              <button
                type="submit"
                className={`mt-5 rounded-md bg-blue-500 py-2 px-4 text-white transition duration-200 hover:bg-blue-600 ${
                  formik.errors.skillName || formik.errors.csvFile || loading
                    ? " cursor-not-allowed opacity-60"
                    : " "
                }`}
                disabled={
                  formik.errors.skillName || formik.errors.csvFile || loading
                }
              >
                <div className="flex flex-row items-center justify-center gap-5">
                  <span>{loading ? "Submitting..." : " Submit"}</span>
                  <span>
                    <ThreeDots
                      height="30"
                      width="30"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={loading}
                    />
                  </span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
      <QuestionTable
        columnsData={questionTableColumns}
        tableData={questionRecords}
      />
    </div>
  );
};

export default Questions;
