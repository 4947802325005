/* eslint-disable no-unused-vars */
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useGroupBy,
  useExpanded,
} from "react-table";
import {
  MdCheckCircle,
  MdCancel,
  MdOutlineError,
  MdVideoLibrary,
  MdVideoLabel,
  MdLensBlur,
  MdDeleteForever,
} from "react-icons/md";
import { useMemo } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const ComplexTable = (props) => {
  const {
    columnsData,
    tableData,
    tableTittle,
    onVideoClick,
    onViewVettingRecordClick,
    onDeleteExamClickHandler,
  } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },

    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setGroupBy,
    state: { pageIndex, pageSize, groupBy, expanded },
    setPageSize,
  } = tableInstance;

  console.log("Page :", page);

  return (
    <div
      className={
        "z-10 flex h-full w-full flex-col  rounded-[20px] bg-white bg-clip-border p-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none sm:overflow-x-auto"
      }
    >
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          {tableTittle}
        </div>
      </div>

      <div className="mt-8 h-full overflow-x-auto " style={{ height: "650px" }}>
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps()}
                    key={index}
                    className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <div className="flex flex-row">
                      {column.canGroupBy && column.Header === "APPLICANT" ? (
                        // If the column can be grouped, let's add a toggle
                        <span
                          className="mr-3"
                          {...column.getGroupByToggleProps()}
                        >
                          {column.isGrouped ? "➡️" : "⬇️  "}
                        </span>
                      ) : null}
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {/* <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <p className="text-xs tracking-wide text-gray-600">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead> */}
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index} className="py-4">
                  {row.cells.map((cell, index) => {
                    console.log("Cell :", cell);
                    if (cell.isGrouped) {
                      return (
                        <>
                          <p className="py-3 text-sm font-bold text-navy-700 dark:text-white">
                            <span {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? "⬇️" : "➡️"}
                            </span>{" "}
                            {cell.render("Cell")} ({row.subRows.length})
                          </p>
                        </>
                      );
                    } else if (cell.isAggregated) {
                      return null;
                    } else if (cell.isPlaceholder) {
                      return <span className="w-5"></span>;
                    } else {
                      let data = "";
                      if (cell.column.id === "applicant") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        );
                      } else if (cell.column.id === "skill") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value ? cell.value : "Problem Solving"}
                          </p>
                        );
                      } else if (cell.column.id === "humanSTATUS") {
                        data = (
                          <div className="flex items-center gap-2">
                            <div className={`rounded-full text-xl`}>
                              {cell.value === "Pass" ? (
                                <MdCheckCircle className="text-green-500" />
                              ) : cell.value === "Fail" ? (
                                <MdCancel className="text-red-500" />
                              ) : cell.value === "not vetted" ? (
                                <MdLensBlur className="text-blue-500" />
                              ) : cell.value === "Not Needed" ? (
                                <MdCancel className="text-red-500" />
                              ) : null}
                            </div>
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value === "not vetted"
                                ? "Queued"
                                : cell.value}
                            </p>
                          </div>
                        );
                      } else if (cell.column.id === "aiVetting") {
                        data = (
                          <div className="flex items-center gap-2">
                            <div className={`rounded-full text-xl`}>
                              {cell.value === "No Cheating Detected" ? (
                                <MdCheckCircle className="text-green-500" />
                              ) : cell.value === "Cheating Detected" ? (
                                <MdOutlineError className="text-orange-500" />
                              ) : cell.value === "being vetted" ? (
                                <MdLensBlur className="text-blue-500" />
                              ) : null}
                            </div>
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value === "being vetted"
                                ? "Being Vetted"
                                : cell.value}
                            </p>
                          </div>
                        );
                      } else if (cell.column.id === "idCheck") {
                        data = (
                          <div className="flex items-center gap-2">
                            <div className={`rounded-full text-xl`}>
                              {cell.value === true ? (
                                <MdCheckCircle className="text-green-500" />
                              ) : cell.value === false ? (
                                <MdCancel className="text-red-500" />
                              ) : !cell.value ? (
                                "-"
                              ) : null}
                            </div>
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          </div>
                        );
                      } else if (cell.column.id === "date") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        );
                      } else if (cell.column.id === "actions") {
                        data = (
                          <div className="flex items-center gap-2">
                            <div className={`rounded-full text-xl`}>
                              {cell.value && cell.value.vetting ? (
                                <MdVideoLibrary
                                  data-tooltip-id="manual-vetting"
                                  data-tooltip-content="Click to start manual vetting"
                                  onClick={() => {
                                    onVideoClick(cell.value.recordId);
                                  }}
                                  className="text-gray-800-500 hover:cursor-pointer"
                                />
                              ) : (
                                <MdVideoLibrary
                                  className="text-gray-600 "
                                  data-tooltip-id="manual-vetting"
                                  data-tooltip-content=" manual vetting"
                                />
                              )}
                            </div>

                            <div className={`rounded-full text-xl`}>
                              <MdVideoLabel
                                data-tooltip-id="view-vetting"
                                data-tooltip-content="View Vetting Record"
                                onClick={() => {
                                  console.log("Cell value :", cell);
                                  onViewVettingRecordClick(cell.value.recordId);
                                }}
                                className="text-gray-800-500 hover:cursor-pointer"
                              />
                            </div>
                            <div className={`rounded-full text-xl`}>
                              <MdDeleteForever
                                data-tooltip-id="delete-vetting"
                                data-tooltip-content="Delete exam"
                                onClick={() => {
                                  console.log("Cell value :", cell);
                                  onDeleteExamClickHandler(cell.value.recordId);
                                }}
                                className="text-red-500 hover:cursor-pointer"
                              />
                            </div>
                            <Tooltip id="manual-vetting" />
                            <Tooltip id="view-vetting" />
                            <Tooltip id="delete-vetting" />
                          </div>
                        );
                      }
                      return (
                        <td
                          className="pt-[14px] pb-[18px] sm:text-[14px]"
                          {...cell.getCellProps()}
                          key={index}
                        >
                          {data}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex items-center justify-between border-t-2 border-gray-600">
        <div>
          <span className="text-s mr-3">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>

          <span className="text-s mr-3">
            Go to page:{" "}
            <input
              type="number"
              min={1}
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              className="border-black mx-2 w-20 rounded border p-1 text-center focus:outline-none focus:ring-2 focus:ring-blue-600 dark:bg-gray-900 dark:text-white"
            />
          </span>

          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="rounded border p-1 text-xs focus:outline-none focus:ring-2 focus:ring-blue-600 dark:bg-gray-900 dark:text-white"
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>

        <div>
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="mx-1 rounded bg-blue-500 px-3 py-1 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {"<<"}
          </button>

          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="mx-1 rounded bg-blue-500 px-3 py-1 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {"<"}
          </button>

          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="mx-1 rounded bg-blue-500 px-3 py-1 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {">"}
          </button>

          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="mx-1 rounded bg-blue-500 px-3 py-1 text-xs text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {">>"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComplexTable;
