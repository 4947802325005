export const demoRequestColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Company",
    accessor: "companyName",
  },
  {
    Header: "Email",
    accessor: "email",
  },

  {
    Header: "Contact Number",
    accessor: "contactNumber",
  },
  {
    Header: "Country",
    accessor: "country",
  },
  {
    Header: "Request Status",
    accessor: "status",
  },
  {
    Header: "Date",
    accessor: "createdAt",
  },
  {
    Header: "Actions",
    accessor: "action",
  },
];
