/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";
import "./slider.css";
import TextField from "components/fields/TextField";
import {
  MediaCommunitySkin,
  MediaOutlet,
  MediaPlayer,
  MediaSlider,
} from "@vidstack/react";
import Radio from "components/radio";
import { useFormik } from "formik";
import { examVettingUpdateSchema } from "../../schemas";
import {
  updateExamVettingStatusHuman,
  updateExamVettingStatusHumanUniversity,
} from "apis";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ResponsiveContainer,
  Brush,
} from "recharts";

const MyModal = ({
  vettingVideoRecord,
  handleCloseModal,
  toggleUpdateRecords,
  isUniversityRecord,
}) => {
  const data = vettingVideoRecord.cumulativeDerivative.cumulativeDerivative;
  const [data1, setData1] = useState(data);

  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);
  const { values, handleBlur, handleChange, errors, touched, setFieldValue } =
    useFormik({
      // handleBlur will validate the field once its out of focus
      initialValues: {
        idCheckHuman: vettingVideoRecord.idCheckHuman ? "true" : "false",
        roomTourHuman: vettingVideoRecord.roomTourHuman ? "true" : "false",
        humanStatus:
          vettingVideoRecord.humanStatus === "not vetted"
            ? null
            : vettingVideoRecord.humanStatus === "Pass"
            ? "true"
            : "false",
        vettingComments: vettingVideoRecord.vettingComments
          ? vettingVideoRecord.vettingComments
          : "",
      },
      validationSchema: examVettingUpdateSchema,
    });

  const [manualCheatingPoints, setManualCheatingPoints] = useState(
    vettingVideoRecord.interventionPointsHuman
      ? vettingVideoRecord.interventionPointsHuman.points
      : []
  );
  const [falsePositives, setFalsePositives] = useState(
    vettingVideoRecord.falsePositives
      ? vettingVideoRecord.falsePositives.points
      : []
  );
  const [videoChapters, setVideoChapters] = useState(
    vettingVideoRecord.videoChapters.chapters
  );

  useEffect(() => {
    (() => {
      if (
        vettingVideoRecord.mergedInterventionPointsAI.points.length > 0 &&
        !vettingVideoRecord.falsePositives
      ) {
        const initialFalseNegatives =
          vettingVideoRecord.mergedInterventionPointsAI.points.map((point) => {
            const startTime = point.start * 1000;
            const endTime = point.end * 1000;
            const isTrue = 0;
            return [startTime, endTime, isTrue];
          });
        setFalsePositives(initialFalseNegatives);
      }
    })();
  }, [vettingVideoRecord]);

  function formatTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  async function onAddManualPointClickHandler() {
    const newPoint = [values.startTime, values.endTime];
    console.log("new point ", newPoint);
    const updatedPoints = [...manualCheatingPoints];
    updatedPoints.push(newPoint);
    setManualCheatingPoints(updatedPoints);
    setFieldValue("startTime", "", false);
    setFieldValue("endTime", "", false);
    startTimeRef.current.value = "";
    endTimeRef.current.value = "";
  }

  async function onRemovePointClickHandler(index) {
    console.log("new point ", index);
    const updatedPoints = [...manualCheatingPoints];
    updatedPoints.splice(index, 1);
    setManualCheatingPoints(updatedPoints);
  }

  async function onFalseNegativeClickHandler(
    startTime,
    endTime,
    indexRecv,
    event
  ) {
    console.log("Start Time", startTime);
    console.log("End Time", endTime);
    console.log("Index ", indexRecv);
    const isChecked = event.target.checked;
    console.log("Checkbox clicked:", isChecked);

    let updatedFalseNegatives;
    if (isChecked) {
      updatedFalseNegatives = falsePositives.map((point, index) => {
        if (index === indexRecv) {
          return [point[0], point[1], 1];
        } else {
          return [point[0], point[1], point[2]];
        }
      });
    } else {
      updatedFalseNegatives = falsePositives.map((point, index) => {
        if (index === indexRecv) {
          return [point[0], point[1], 0];
        } else {
          return [point[0], point[1], point[2]];
        }
      });
    }
    setFalsePositives(updatedFalseNegatives);
  }

  async function onSubmitClickHandler(event) {
    event.preventDefault();
    console.log("Values ", values);

    let roomTourHuman;
    let idCheckHuman;
    let humanStatus;

    if (values.roomTourHuman === "false") {
      roomTourHuman = false;
    } else {
      roomTourHuman = true;
    }

    if (values.idCheckHuman === "false") {
      idCheckHuman = false;
    } else {
      idCheckHuman = true;
    }
    if (values.humanStatus === "false") {
      humanStatus = "Fail";
    } else {
      humanStatus = "Pass";
    }
    const interventionPointsHuman = {
      points: manualCheatingPoints,
    };
    const falsePositvesBody = {
      points: falsePositives,
    };

    try {
      let response;
      if (!isUniversityRecord) {
        response = await updateExamVettingStatusHuman(
          vettingVideoRecord.id,
          humanStatus,
          values.vettingComments,
          idCheckHuman,
          roomTourHuman,
          interventionPointsHuman,
          falsePositvesBody
        );
      } else {
        response = await updateExamVettingStatusHumanUniversity(
          vettingVideoRecord.id,
          humanStatus,
          values.vettingComments,
          idCheckHuman,
          roomTourHuman,
          interventionPointsHuman,
          falsePositvesBody
        );
      }

      if (response.status === 200) {
        console.log("record updated");
        handleCloseModal();
        toggleUpdateRecords();
      } else {
        alert("issue ");
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  }

  function convertTimeToSeconds(timeStr) {
    const [h, m, s] = timeStr.split(":").map(Number);
    return h * 3600 + m * 60 + s;
  }

  function calculatePercentage(startTime, endTime, totalDuration) {
    const startTimeSeconds = convertTimeToSeconds(startTime);
    const endTimeSeconds = convertTimeToSeconds(endTime);
    const totalDurationSeconds = convertTimeToSeconds(totalDuration);

    const intervalDuration = endTimeSeconds - startTimeSeconds;
    const percentage = (intervalDuration / totalDurationSeconds) * 100;

    return percentage;
  }

  function stepSizeChangeHandler(stepSize) {
    const updatedData = data.reduce((acc, curr, idx) => {
      const groupIndex = Math.floor(idx / stepSize);
      if (!acc[groupIndex]) {
        acc[groupIndex] = { frameNumber: 0, switchValue: 0 };
      }
      acc[groupIndex].switchValue += curr.switchValue;
      acc[groupIndex].frameNumber = parseInt(curr.frameNumber);
      return acc;
    }, []);

    setData1(updatedData);
  }

  return (
    <div className="modal fixed top-10 left-0 flex h-screen w-full items-center justify-center pt-20">
      <div className="modal-overlay absolute h-screen w-screen bg-gray-900 opacity-50"></div>
      <div className="modal-container z-50 max-h-full w-11/12 overflow-y-auto rounded bg-white shadow-lg md:w-10/12 lg:w-9/12">
        <div className="modal-content py-4 px-6 text-left">
          <h2
            onClick={handleCloseModal}
            className="modal-header mb-4  text-right text-2xl font-bold hover:cursor-pointer"
          >
            X
          </h2>
          <h2 className="modal-header mb-4 text-center text-3xl font-bold">
            {`${vettingVideoRecord.applicant.firstName} ${
              vettingVideoRecord.applicant.lastName
            } : ${
              vettingVideoRecord.skill
                ? vettingVideoRecord.skill.name
                : "Coding"
            } Exam`}
          </h2>
          <div className="flex flex-col items-center">
            <MediaPlayer
              title="Exam Video"
              src={`${process.env.REACT_APP_BACKEND_URL}/${vettingVideoRecord.mergedVideoUrl}`}
              crossorigin=""
              aspectRatio={16 / 9}
            >
              <MediaOutlet>
                <track
                  src={`${process.env.REACT_APP_BACKEND_URL}/${vettingVideoRecord.inteventionChapterFile}`}
                  srcLang="en-US"
                  kind="chapters"
                  default
                />
                {/* <MediaPlayButton /> */}
              </MediaOutlet>

              <MediaCommunitySkin />
              {videoChapters && (
                <div className="mt-10 ml-5 mb-2 mr-4 flex flex-row ">
                  {videoChapters.map((point, index) => {
                    const percentage = calculatePercentage(
                      point[0],
                      point[1],
                      vettingVideoRecord.videoLength
                    );
                    if (point[2].toLowerCase() === "non-cheating") {
                      return (
                        <MediaSlider
                          key={index}
                          min={0}
                          max={100}
                          value={0}
                          aria-label="..."
                          className="z-50 m-0  h-0.5"
                          style={{ width: `${percentage}%` }}
                          trackFillClass="not-cheating"
                          trackProgressClass="not-cheating"
                          disabled={true}
                        />
                      );
                    } else {
                      return (
                        <MediaSlider
                          key={index}
                          min={0}
                          max={100}
                          value={0}
                          aria-label="..."
                          className="z-50 m-0 h-0.5"
                          style={{ width: `${percentage}%` }}
                          trackFillClass="cheating"
                          trackProgressClass="cheating"
                          disabled={true}
                        />
                      );
                    }
                  })}
                </div>
              )}
            </MediaPlayer>
          </div>

          <div className="my-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              Cheating Points Human
            </h4>
            <div className="flex w-full flex-row items-center justify-center gap-5 text-2xl">
              <>
                <label>Start Time: </label>
                <input
                  type="text"
                  name="startTime"
                  placeholder="00:20"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  ref={startTimeRef}
                  className=" w-32 rounded-md border border-white bg-white px-4 text-gray-800 shadow-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </>
              <>
                <label>End Time: </label>
                <input
                  type="text"
                  name="endTime"
                  id="endTime"
                  placeholder="01:30"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  ref={endTimeRef}
                  className=" w-32 rounded-md border border-white bg-white px-4 text-gray-800 shadow-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </>
              <div>
                <button
                  disabled={
                    errors.startTime ||
                    !values.startTime ||
                    errors.endTime ||
                    !values.endTime
                  }
                  onClick={onAddManualPointClickHandler}
                  className="flex flex-row items-center rounded-xl bg-green-500 px-1 py-1 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 disabled:cursor-not-allowed disabled:bg-gray-600 dark:bg-green-400 dark:text-white dark:hover:bg-green-300  dark:active:bg-green-200"
                >
                  <MdAddCircleOutline className="text-2xl" />
                </button>
              </div>
            </div>
            {errors.startTime && touched.startTime && (
              <div className="mt-3 text-center text-xl text-red-400">
                {errors.startTime}
              </div>
            )}
            {errors.endTime && touched.endTime && (
              <div className=" mt-3 text-center text-xl text-red-400">
                {errors.endTime}
              </div>
            )}
            {manualCheatingPoints.length > 0 && (
              <div className="mt-10">
                <p className="mb-5 text-center text-lg font-semibold">
                  Human detected unusual behavior in the following timestamps
                </p>

                <div className="todo-text-alignment">
                  {manualCheatingPoints.map((point, index) => {
                    const startTimeInSeconds = point[0]; // Convert start time to minutes
                    const endTimeInSeconds = point[1]; // Convert end time to minutes
                    return (
                      <div
                        key={`${index}_${point[0]}`}
                        className="mb-2 flex w-full items-center justify-center gap-5 text-2xl"
                      >
                        <h3 className="modal-subheader font-semibold">
                          {startTimeInSeconds} - {endTimeInSeconds}
                        </h3>
                        <div>
                          <button
                            onClick={() => {
                              onRemovePointClickHandler(index);
                            }}
                            className="flex flex-row items-center rounded-xl bg-red-500 px-1 py-1 text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
                          >
                            <MdRemoveCircleOutline className="text-xl" />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <div className="my-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              Gaze Switches
            </h4>
            <div className="min-h-5/6 flex  w-full items-start justify-center gap-5 bg-white text-2xl">
              <ResponsiveContainer width="90%" height={500}>
                <LineChart
                  data={data1}
                  margin={{
                    top: 50,
                    right: 100,
                    left: 100,
                    bottom: 50,
                  }}
                >
                  <Line
                    type="monotone"
                    dataKey="switchValue"
                    stroke="#8884d8"
                    strokeWidth={3}
                    dot={false}
                  />
                  <Brush
                    onChange={function noRefCheck() {}}
                    gap={60}
                    endIndex={60}
                  />
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <XAxis dataKey="frameNumber">
                    <Label
                      value={"Frame Number "}
                      offset={60}
                      position={"bottom"}
                    />
                  </XAxis>
                  <YAxis
                    dataKey="switchValue"
                    label={{
                      value: "Rate of Switches",
                      angle: -90,
                      position: "left",
                      offset: 50,
                    }}
                  />

                  <Tooltip />
                </LineChart>
              </ResponsiveContainer>

              <div className="w-20">
                <label
                  for="countries"
                  class="mb-2  block text-sm font-medium text-gray-900 dark:text-gray-400"
                >
                  Choose step size
                </label>
                <select
                  defaultValue="10"
                  onChange={(e) =>
                    stepSizeChangeHandler(parseInt(e.target.value))
                  }
                  id="countries"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>
          </div>

          <div className="my-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              AI Confidence Score
            </h4>
            <div className="flex w-full items-center justify-center gap-5 text-2xl">
              {vettingVideoRecord.aiConfidenceScore == null ? (
                <h3 className="modal-subheader ">Not Available</h3>
              ) : (
                <h3 className="modal-subheader font-semibold">
                  {`${vettingVideoRecord.aiConfidenceScore}/5`}
                </h3>
              )}
            </div>
          </div>
          <div className="my-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              Identity Verification
            </h4>
            <div className="flex w-full items-center justify-center gap-5 text-2xl">
              <h3 className="modal-subheader font-semibold">
                AI Result:{" "}
                <>
                  {vettingVideoRecord.idCheck ? (
                    <span className="text-green-900"> Passed </span>
                  ) : (
                    <span className="text-red-900"> Failed </span>
                  )}
                </>
              </h3>

              <h3 className="modal-subheader font-semibold">Human Result:</h3>

              <div>
                <Radio
                  id="pass_idCheck_human"
                  name="idCheckHuman"
                  color="green"
                  value={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.idCheckHuman === "true"}
                />
                <label> Passed</label>
              </div>

              <div>
                <Radio
                  id="fail_idCheck_human"
                  name="idCheckHuman"
                  color="red"
                  value={false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.idCheckHuman === "false"}
                />
                <label>Failed</label>
              </div>
            </div>
          </div>

          <div className="mt-10 rounded-md bg-gray-200 p-5">
            <h4 className=" mb-5 w-full text-center text-3xl font-bold">
              Room Tour
            </h4>
            <div className="flex w-full items-center justify-center gap-5 text-2xl">
              <h3 className="modal-subheader font-semibold">
                AI Result:{" "}
                <>
                  {vettingVideoRecord.roomTour ? (
                    <span className="text-green-900"> Passed </span>
                  ) : (
                    <span className="text-red-900"> Failed </span>
                  )}
                </>
              </h3>

              <h3 className="modal-subheader font-semibold">Human Result:</h3>
              <div>
                <Radio
                  id="pass_roomTour_human"
                  name="roomTourHuman"
                  color="green"
                  value={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.roomTourHuman === "true"}
                />
                <label> Passed</label>
              </div>

              <div>
                <Radio
                  id="fail_roomTour_human"
                  name="roomTourHuman"
                  color="red"
                  value={false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.roomTourHuman === "false"}
                />
                <label>Failed</label>
              </div>
            </div>
          </div>

          <div className="mt-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              Gaze Detection{" "}
            </h4>
            <p className="mb-5 text-center text-lg font-semibold">
              AI detected unusual behavior in the following timestamps
            </p>
            {vettingVideoRecord.falsePositives ? (
              <div className="todo-text-alignment">
                {vettingVideoRecord.falsePositives.points.map(
                  (point, index) => {
                    const startTimeInSeconds = formatTime(point[0]); // Convert start time to minutes
                    const endTimeInSeconds = formatTime(point[1]); // Convert end time to minutes
                    return (
                      <div
                        key={index}
                        className="my-2 flex w-full items-center justify-center gap-5 text-2xl"
                      >
                        <h3 className="modal-subheader font-semibold">
                          {startTimeInSeconds} - {endTimeInSeconds}
                        </h3>
                        <h3 className="modal-subheader font-semibold">
                          False Alarm?
                        </h3>
                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={point[2] === 1}
                            onClick={(event) =>
                              onFalseNegativeClickHandler(
                                point[0],
                                point[1],
                                index,
                                event
                              )
                            }
                            className="checked:border-transparent mr-2 h-5 w-5 cursor-pointer rounded-md border border-gray-300 bg-white checked:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          />
                          <label>Yes</label>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            ) : (
              <div className="todo-text-alignment">
                {vettingVideoRecord.mergedInterventionPointsAI.points.map(
                  (point, index) => {
                    const startTimeInSeconds = formatTime(point.start * 1000); // Convert start time to minutes
                    const endTimeInSeconds = formatTime(point.end * 1000); // Convert end time to minutes
                    return (
                      <div
                        key={index}
                        className="my-2 flex w-full items-center justify-center gap-5 text-2xl"
                      >
                        <h3 className="modal-subheader font-semibold">
                          {startTimeInSeconds} - {endTimeInSeconds}
                        </h3>
                        <h3 className="modal-subheader font-semibold">
                          False Alarm?
                        </h3>
                        <div>
                          <input
                            type="checkbox"
                            onClick={(event) =>
                              onFalseNegativeClickHandler(
                                point[0] * 1000,
                                point[1] * 1000,
                                index,
                                event
                              )
                            }
                            className="checked:border-transparent mr-2 h-5 w-5 cursor-pointer rounded-md border border-gray-300 bg-white checked:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          />
                          <label>Yes</label>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>

          <div className="mt-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              Overall Result
            </h4>
            <div className="flex w-full items-center justify-center gap-5 text-2xl">
              <div>
                <Radio
                  id="pass_humanStatus"
                  name="humanStatus"
                  color="green"
                  value={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.humanStatus === "true"}
                />
                <label> Passed</label>
              </div>

              <div>
                <Radio
                  id="fail_humanStatus"
                  name="humanStatus"
                  color="red"
                  value={false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.humanStatus === "false"}
                />
                <label>Failed</label>
              </div>
              {errors.humanStatus && touched.humanStatus && (
                <div className="text-red-400">{errors.humanStatus}</div>
              )}
            </div>
          </div>

          <div className="mt-10 rounded-md bg-gray-200 p-5">
            <h4 className="mb-5 w-full text-center text-3xl font-bold">
              Vetting Comments
            </h4>
            <div className="flex w-full items-center justify-center gap-5 text-2xl">
              <TextField
                label="Comments"
                placeholder="Share your observations while vetting."
                id="textarea"
                cols="50"
                rows="2"
                name="vettingComments"
                value={values.vettingComments}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="mt-5 flex items-center justify-center gap-5 pb-10">
            <button
              className="modal-close rounded bg-red-500 py-2 px-4 font-bold text-white hover:bg-red-700"
              onClick={handleCloseModal}
            >
              Close
            </button>

            <button
              className="modal-close rounded bg-green-500  py-2 px-4 font-bold text-white hover:bg-green-700 disabled:cursor-not-allowed disabled:bg-gray-600"
              onClick={onSubmitClickHandler}
              disabled={
                errors.idCheckHuman ||
                !values.idCheckHuman ||
                errors.roomTourHuman ||
                !values.roomTourHuman ||
                errors.humanStatus ||
                !values.humanStatus
              }
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyModal;
